import { formatDateYYYYMMGG } from "./reformatDate";

export const registerField = (e, setStateAction) => {
  const { value, name } = e.target;
  if (name === "birthDate") {
    let dateString = value;
    let dateParts = value.includes("/")
      ? dateString.split("/")
      : dateString.split("-");
    let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    setStateAction((prevState) => ({
      ...prevState,
      [name]: formatDateYYYYMMGG(dateObject),
    }));
  } else setStateAction((prevState) => ({ ...prevState, [name]: value }));
};
