import { isMobile } from "../consts/conditionals";
import { secondaryA } from "./palette";

export const filterBox = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: isMobile ? "fit-content" : "172.98px",
  gap: isMobile ? "" : "30px",
};
export const filterInputsBox = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px",
  gap: isMobile ? "20px" : "30px",

  flexDirection: isMobile ? "column" : "row",
  width: isMobile ? "323px" : "765px",
  height: isMobile ? "176px" : "65.34px",
};

export const docSelectIcon = {
  position: "absolute",
  transform: isMobile
    ? "translatex(10.5px) translatey(11.5px)"
    : "translatex(10.5px) translatey(16.5px)",
};
export const docSelectLabel = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingLeft: "15px",

  fontFamily: "Poppins",
  fontDtyle: "normal",
  fontWeight: "500",
  fontSize: "17.7537px",
  lineHeight: "23px",

  border: "none",
  appearance: "none",
  backgroundColor: "transparent",

  cursor: "pointer",

  color: "#262626",

  position: "absolute",
  transform: isMobile
    ? "translatex(10.5px) translatey(11.5px)"
    : "translatex(13.5px) translatey(16.5px)",
};
export const docBox = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: "#ffffff",
  gap: isMobile ? "7px" : "10px",

  padding: isMobile ? "20px 0px" : "27.8049px 0px",
  width: isMobile ? "369px" : "765px" /* 
  minHeight: isMobile ? "114px" : "160.15px", */,
  borderRadius: isMobile ? "10px" : "13.9024px",
};
export const docName = {
  /* 
  width: "209px", */
  height: isMobile ? "24px" : "28px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: isMobile ? "18px" : "20.8537px",
  lineHeight: "133.02%",
  color: "#000000",
};

export const docInfo = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0px",

  gap: isMobile ? "3px" : "4.17px",
  width: isMobile ? "247px" : "335px",
  height: isMobile ? "40px" : "56.17px",
};
export const typeLabel = {
  textAlign: "center",
  height: "28px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: isMobile ? "14px" : "19.8537px",
  width: isMobile ? "360px" : "550px",
  lineHeight: "133.02%",
  color: "#000000",
};
export const dateLabel = {
  /* 
  width: isMobile?"200px":"278.05px", */
  height: isMobile ? "17px" : "24px",
  fontSize: isMobile ? "13px" : "18.0732px",

  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "133.02%",
  textAlign: "center",
  color: "#000000",
};
export const statusInfo = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px",
  gap: isMobile ? "10px" : "13.9px" /* 
  width:isMobile? "184px": "328.27px", */,
  height: isMobile ? "24px" : "33.37px",
};

export const noteLabel = {
  width: isMobile ? "254px" : "353.12px",
  height: isMobile ? "34px" : "48px",

  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: isMobile ? "13px" : "18.0732px",
  lineHeight: "133.02%",
  textAlign: "center",

  color: "#686868",
};

export const statusIconBackground = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  width: isMobile ? "24px" : "30.59px",
  height: isMobile ? "24px" : "30.59px",
  borderRadius: "100%",
};

export const statusIconSize = {
  height: isMobile ? "15px" : "20.59px",
  width: isMobile ? "15px" : "20.59px",
};

export const statusLabel = {
  height: isMobile ? "17px" : "24px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: isMobile ? "13px" : "18.0732px",
  lineHeight: "133.02%",
  textAlign: "center",
};

export const buttonApproval = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: isMobile ? "6px 10px" : "8.34146px 13.9024px",
  gap: isMobile ? "10px" : "13.9px",

  width: isMobile ? "246px" : "354.8px",
  height: isMobile ? "31px" : "43.68px",
  border: "none",
  cursor: "pointer",
  background: "#123786",
  borderRadius: isMobile ? "8px" : "11.122px",
};
export const buttonApprovalText = {
  width: isMobile ? "226px" : "327px",
  height: isMobile ? "19px" : "27px",

  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: isMobile ? "13.6417px" : "20.3555px",
  lineHeight: "133.02%",

  color: "#ffffff",
};
export const download = {
  cursor: "pointer",
  width: isMobile ? "25px" : "35px",
  height: isMobile ? "25px" : "35px",

  transform: isMobile
    ? "translatex(155px) translatey( 0px)"
    : "translatex(325px) translatey(-40px)",
};
export const buttonLoad = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: isMobile ? "6px 10px" : "8.34146px 13.9024px",
  gap: isMobile ? "10px" : "13.9px",

  width: isMobile ? "169px" : "234.8px",
  height: isMobile ? "31px" : "43.68px",
  border: "none",
  cursor: "pointer",
  background: "#123786",
  borderRadius: isMobile ? "8px" : "11.122px",
};
export const buttonLoadText = {
  width: isMobile ? "149px" : "207px",
  height: isMobile ? "19px" : "27px",

  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: isMobile ? "14.6417px" : "20.3555px",
  lineHeight: "133.02%",

  color: "#ffffff",
};
export const adminButtonsBox = {
  position: "absolute",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  padding: "0px",
  gap: "14.24px",
  marginTop: "30px",
  width: "272px",
  height: "64px",
  transform: isMobile ? "translatey(200px)" : "translatey(310px)",
};

export const adminButtonApprove = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: isMobile ? "92px" : "114.48px",
  height: isMobile ? "36px" : "46.79px",
  gap: "10px",
  padding: "8px 10px",
  borderRadius: "10px",
  backgroundColor: secondaryA.hex,
  cursor: "pointer",
};
export const adminButtonReject = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: isMobile ? "74px" : "93.48px",
  height: isMobile ? "36px" : "46.79px",
  gap: "10px",
  padding: "8px 10px",
  borderRadius: "10px",
  backgroundColor: "#FF0000",
  cursor: "pointer",
};
export const adminButtonChioceText = {
  fontSize: isMobile ? "15px" : "18px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  color: "#FFFFFF",
};
export const filterLabel = {
  /* 
  width: isMobile ? "65px" : "216.36px", */ /* 
  height: isMobile ? "17px" : "24px", */

  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: isMobile ? "13px" : "18.0732px",
  lineHeight: "133.02%",

  textAlign: "center",

  color: "#ffffff",
};

export const notesModalInputBox = {
  gap: isMobile ? "10px" : "14px",

  width: isMobile ? "323px" : "316px",
  height: isMobile ? "153px" : "249px",
};
export const notesModalInputField = {
  padding: isMobile ? "15px" : "22px",

  gap: isMobile ? "10px" : "12.17px",
  border: "none",
  resize: "none",
  width: isMobile ? "323px" : "316px",
  height: isMobile ? "120px" : "215px",

  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "15px",
  lineHeight: "17px",

  borderRadius: isMobile ? "5px" : "6.08359px",
  color: "#717171",
};
