import { isMobile } from "../consts/conditionals";

 export const headerBox = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px",
  gap: "136.27px",
  width: "90%",
  height: "45.55px",
  margin: "69px auto",
};
export const easyLogoHeader = {
  width: isMobile ? "122px" : "173px",
  height: isMobile ? "64px" : "85.55px",
};
export const linksBox = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  padding: "0px",
  gap: "10px",
 
  width: "182px",
  height: "20px",
};

export const headerLinkBox = {
  
  width: "52px",
  height: "20px",
};

export const headerLinkText = {
  width: "52px",
  height: "20px",

  fontFamily: "Poppins",
  fontSstyle: "normal",
  fontWeight: "500",
  fontSize: "15px",
  lineHeight: "133.02%",

  textAlign: "center",
};
