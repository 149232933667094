import { useStoryblok, StoryblokComponent } from "@storyblok/react";
import { useEffect } from "react";

function App() {
  const story = useStoryblok("home", { version: "draft" });


  useEffect(() => {
    const pathName = window.location.pathname;
    if (pathName === "/") {
      window.location.href = window.location.origin + "/login";
    }
  }, []);

  if (!story || !story.content) {
    return <div>Loading...</div>;
  }

  return <StoryblokComponent blok={story.content} />;
}

export default App;
