import { useState } from "react";
import { LOGOUT } from "../api/endpointList";
import { apiURL } from "../consts/URLs";
import { fetchRequest } from "../utils/fetchRequest";
import { getOptions } from "../utils/getOption";

const useLogout = (token) => {
  const [logoutResponse, setLogoutResponse] = useState();
  const [logoutResult, setLogoutResult] = useState();

  const logout = () => {
    const url = `${apiURL}/${LOGOUT}`;
    const options = getOptions({ method: "POST", token: token });
    fetchRequest(url, options, setLogoutResponse, setLogoutResult);
  };

  return { logoutResponse, logoutResult, logout };
};
export default useLogout;
