export const getUserType = (type) => {
  if (type === 0) {
    return "Normal";
  }

  if (type === 1) {
    return "Manager";
  }

  if (type === 2) {
    return "Admin";
  }
};
