import { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import { isMobile } from "../consts/conditionals";
import { appHostURL } from "../consts/URLs";
import useAccount from "../hooks/useAccount";
import useLogin from "../hooks/useLogin";
import useToggle from "../hooks/useToggle";
import {
  accessButton,
  accessButtonText,
  accessInput,
  accessLink,
  accessMainBox,
  accessMainLogo,
  accessPageTitleStyle,
  backgroundWrapper,
  checkboxContainer,
  checkboxInput,
  eyeIcon,
  forgotPwLink,
  logBox,
  logText,
} from "../styles/accessGeneralStyle";
import "../styles/wallpaper.css";
import { deleteCookie, getCookie, setCookie } from "../utils/handleCookie";
import { registerField } from "../utils/registerField";

const LoginPage = ({ blok, setUser, token, setToken }) => {
  const { loginResponse, loginResult, login } = useLogin();

  const [isToggled, toggle] = useToggle();
  const navigate = useNavigate();

  const [rememberCredential, setRememberCredential] = useState(false);

  
  const [logMessage, setLogMessage] = useState({
    isShown: false,
    text: "",
    bgColor: "",
    color:""
  });

  const [loginFields, setLoginFields] = useState({
    email: "",
    password: "",
  });

  const handleRemember = (e) => {
    const { checked } = e.target;
    setRememberCredential(checked);
  };

  useEffect(() => {
    /* console.log(loginResponse, loginResult); */
    if (getCookie("appToken") && getCookie("userEssential")) {
      navigate("/profile");
    } else if (loginResponse?.ok) {
      setUser(loginResult.user);
      setToken(loginResult.token);
      if (rememberCredential) {
        setCookie(
          "userEssential",
          JSON.stringify({
            id: loginResult.user.id,
            type: loginResult.user.type,
          })
        );
        setCookie("appToken", loginResult.token);
      }
      if (loginResult.user.type === "normal") {
        navigate("/documents");
      } else {
        navigate("/accounts");
      }
    } else if (loginResponse && !loginResponse?.ok) {
      setLogMessage({
        isShown: true,
        text: "Errore, riprova a inserire le credenziali corrette!",
        bgColor:"transparent",
        color:"red"
      });
    }
  }, [loginResponse]);

  const handleConfirm = (e) => {
    const { email, password } = loginFields;
    login(email, password);
  };

  const handleRegisterFields = (e) => {
    registerField(e, setLoginFields);
    setLogMessage({ isShown: false, text: "" });
  };

  return (
    <div
      /* className={`wallpaper${isMobile ? "MB" : "DT"}`} */
      style={backgroundWrapper}
    >
      <p style={accessPageTitleStyle}>Login</p>
      <div style={accessMainBox}>
        <img style={accessMainLogo} src="/images/3sy.png" />
        <input
          style={accessInput}
          type={"text"}
          name="email"
          placeholder="Email"
          value={loginFields.email}
          onChange={handleRegisterFields}
        />
        <div>
          <input
            style={accessInput}
            type={isToggled ? "text" : "password"}
            name="password"
            placeholder="Password"
            value={loginFields.password}
            onChange={handleRegisterFields}
          />
          {isToggled ? (
            <AiFillEye style={eyeIcon} onClick={toggle} />
          ) : (
            <AiFillEyeInvisible style={eyeIcon} onClick={toggle} />
          )}
        </div>
        <div style={checkboxContainer}>
          <input
            style={checkboxInput}
            type={"checkbox"}
            checked={rememberCredential}
            onChange={handleRemember}
          />
          <p style={accessLink}>Ricordami</p>
        </div>
        <button style={accessButton} onClick={handleConfirm}>
          <p style={accessButtonText}>Accedi</p>
        </button>
      </div>
      <Link style={forgotPwLink} to={"/recovery-password"}>
        <p style={accessLink}>Password dimenticata?</p>
      </Link>
      {logMessage.isShown && (
        <div style={logBox}>
          <p style={logText}>{logMessage.text}</p>
        </div>
      )}
      <Footer />
    </div>
  );
};
export default LoginPage;
