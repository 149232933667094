import { isMobile } from "../consts/conditionals";
import { background, primary, secondaryA, secondaryB } from "./palette";

export const backgroundWrapper = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "spaceAround",
  alignItems: "center",
  minHeight: "100%",
  backgroundRepeat: "noRepeat",
  backgroundSize: "100vw 100vh",
  minHeight: "100vh",
  minWidth: "100%",
  backgroundColor: background.hex,
};
export const contentBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "0",
  margin: "0 auto",
  gap: isMobile ? "29px" : "35px" /* 
  width: isMobile ? "323px" : "765px",   */,
  borderRadius: isMobile ? "10px" : "1370.68px",
  minHeight: isMobile ? "" : "20vh",
};

export const pageTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "22px",
  textAlign: "center",
  color: "white",

  fontSize: isMobile ? "18px" : "26px" /* 
  width: isMobile ? "181px" : "765px", */ /* 
  height: isMobile ? "13px" : "18.07px", */,
};

export const longButton = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "10px",

  gap: "10px",
  padding: isMobile ? "20px 0px" : "30px 0px",
  width: isMobile ? "323px" : "765px",
  height: isMobile ? "21px" : "41px",

  cursor: "pointer",
};
export const addButtonExtra = {
  background: "rgba(255, 255, 255, 0.9)",
  border: "1px solid #FFFFFF",
};

export const addNewDocIcon = {
  width: "33px",
  height: "33px",
};

export const longbuttonText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  color: "#000000",
  lineHeight: "133.02%",

  fontSize: isMobile ? "15px" : "18px" /* 
  width: isMobile ? "248px" : "251px", */,
  height: isMobile ? "20px" : "24px",
};

export const displayOnModalOpen = {
  filter: "blur(10px)",
  pointerEvents: "none",
};

export const select = {
  outline: "none",
  border: "none",
  appearance: "none",
  background: "#ffffff",
  paddingLeft: isMobile ? "28px" : "32px",
  borderRadius: isMobile ? "5px" : "6.08359px",
  cursor: "pointer",
  color: "#757575",
};

export const selectIcon = {
  position: "absolute",
  color: "#757575",
  transform: isMobile
    ? " translatey(36.36px) translatex(10.5px)"
    : "translatey(53.5px) translatex(17.36px)",
};

export const inputBox = {
  display: "flex",
  flexDirection: "column",

  gap: isMobile ? "10px" : "20px",
  width: isMobile ? "323px" : "765px" /* 
  height: isMobile ? "74px" : "90.04px", */,
};
export const inputLabel = {
  fontSize: isMobile ? "15px" : "18.2508px" /* 
  width: isMobile ? "193.8px" : "459px", */,
  height: isMobile ? "13px" : "15.82px",

  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "18.2508px",
  lineHeight: "20px",

  color: "#ffffff",
};
export const inputBoxNames = {
  display: "flex",
  flexDirection: isMobile ? "column" : "row",
  width: isMobile ? "323px" : "765px",
  gap: "17px",
};

export const shortInput = {
  outline: "none",
  border: "none",
  appearance: "none",
  background: "#ffffff",
  fontFamily: "Poppins",
  fontDtyle: "normal",
  fontWeight: "500",
  lineHeight: "20px",

  fontSize: isMobile ? "14.6417px" : "15.2508px",

  height: isMobile ? "47px" : "57.19px",
  padding: isMobile ? " 0 0 0 20px" : "  0 0 0 30px",
  background: "#ffffff",
  borderRadius: "6.08359px",
};
export const longInput = {
  outline: "none",
  border: "none",
  appearance: "none",
  background: "#ffffff",
  fontFamily: "Poppins",
  fontDtyle: "normal",
  fontWeight: "500",
  lineHeight: "20px",
  fontSize: isMobile ? "14.6417px" : "15.2508px",
  padding: isMobile ? " 0 0 0 20px" : "  0 0 0 30px",
  height: isMobile ? "47px" : "57.19px",
  borderRadius: isMobile ? "5px" : "6.08359px",
};

export const modalInputDimension = {
  padding: isMobile ? " 0 0 0 20px" : "  0 0 0 30px",
  maxWidth: isMobile ? "" : "315.28px",
  height: isMobile ? "45.04px" : "45.04px",
};
export const modalBackground = {
  position: "fixed",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "60px 25px",
  gap: "10px",
  top: "50%",
  left: "50%",

  marginTop: isMobile ? "-364px" : "-450px",
  marginLeft: isMobile ? "-208px" : "-481px",

  /*  height: isMobile ? "fit-content" : "fit-content", */
  width: isMobile ? "365px" : "962px",
  height: isMobile? "668px":"789px",

  zIndex: "1",
  backgroundColor: secondaryB.hex,
  borderRadius: "10px",
};

export const modalContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px",
  gap: "50px",
  /* height: "620.68px",  */
};
export const modalContentBox = {
  display: "flex",
  flexDirection: "column",
  padding: "0px",
  gap: "14px",
  width: isMobile ? "315.28px" : "316px",
};
export const modalTitleBox = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px",
  gap: "13.48px",

  height: isMobile ? "31px" : "35px",
};
export const modalTitleText = {
  height: isMobile ? "31px" : "35px",

  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: isMobile ? "23px" : "25px",
  lineHeight: "133.02%",

  textAlign: "center",

  color: "#ffffff",
};
export const modalUploadWrapper = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  paddingTop: "10px",

  paddingBottom: "10px",
  gap: "14px",
};
export const modalUploadInputBox = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: isMobile ? "14.6417px" : "30px 14.6417px",
  gap: "9.76px",

  cursor: "pointer",
  width: "280.28px",
  height: isMobile ? "89.04px" : "120.76",

  border: "2.92833px dashed #FFFFFF",
  borderRadius: "4.88056px",
};
export const modalDocInput = {
  display: "none",
};
export const modalUploadIcon = {
  cursor: "pointer",
};
export const modalUploadText = {
  textAlign: "center" /* 
  width: isMobile ? "176px" : "280px", */,
  height: isMobile ? "16px" : "17px",

  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: isMobile ? "14.6417px" : "15px",
  lineHeight: "17px",

  cursor: "pointer",

  color: "#ffffff",
};
export const modalButtonBox = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "14.6417px",
  gap: "9.76px",

  width: isMobile ? "315.28px" : "315.28px",
  height: isMobile ? "45.88px" : "45.88px",
  border: "2.92833px solid #ffffff",
  borderRadius: "4.88056px",

  background: secondaryA.hex,

  cursor: "pointer",
};
export const modalButtonText = {
  width: isMobile ? "" : "220px",
  height: isMobile ? "" : "22px",

  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: isMobile ? "14.6417px" : "20px",
  lineHeight: isMobile ? "16px" : "22px",

  color: "#ffffff",
};
export const closeIcon = {
  position: "absolute",
  left: "87.12%",
  right: "6.85%",
  top: "3.44%",
  bottom: "93.27%",
  cursor: "pointer",
};
export const modalProgressBarContainer = {
  height: "5px",
  width: "100%",
  border: "1px solid white",
  background: "rgba(255, 255, 255, 0.3)",
  borderRadius: "20px",
};
export const modalProgressBarLevel = {
  height: "5px",
  borderRadius: "20px",
  backgroundColor: "white",
};
