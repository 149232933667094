export const getPropertiesNames = (object, preference, propertyNames) => {
  let fieldsNames = []; 
  const keys = Object.keys(object);
  if (preference && propertyNames) {
    if (propertyNames.every((elem) => keys.includes(elem))) {
      if (preference === "only") {
        fieldsNames = propertyNames;
      } else if (preference === "exclude") {
        fieldsNames = keys.filter((elem) => {
          return !propertyNames.includes(elem);
        });
      } else fieldsNames = ["prop doesn't match"];
    }
  } else fieldsNames = keys;

  return fieldsNames;
};
