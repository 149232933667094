import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useLogout from "../hooks/useLogout";
import { logoutBox, logoutIcon, logoutText } from "../styles/logoutAreaStyle";
import { deleteCookie } from "../utils/handleCookie";

export const LogoutArea = ({ blok, token }) => {
  const { logoutResponse, logoutResult, logout } = useLogout({
    token: token,
  });
  const navigate = useNavigate();

  const handleLogOut = () => { 
    deleteCookie("appToken");
    deleteCookie("userEssential");
    deleteCookie("userSelectedId");
    navigate("/login");
    logout(token);
    window.location.reload();
  };

  useEffect(() => {
    if (logoutResponse?.ok) {
    }
  }, [logoutResponse]);

  return (
    <div style={logoutBox} onClick={handleLogOut}>
      <img style={logoutIcon} src="/images/icons/logout.png" />
      <p style={logoutText}>Logout</p>
    </div>
  );
};
export default LogoutArea;
