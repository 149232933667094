import { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import { changePasswordInputs } from "../consts/authenticationPasswordInputs";
import { isMobile } from "../consts/conditionals";
import useChangePassword from "../hooks/useChangePassword";
import useToggle from "../hooks/useToggle";
import {
  accessButton,
  accessButtonText,
  accessInput,
  accessLink,
  accessMainBox,
  accessMainLogo,
  accessPageTitleStyle,
  backgroundWrapper,
  eyeIcon,
  forgotPwLink,
  logBox,
  logText,
} from "../styles/accessGeneralStyle";
import { registerField } from "../utils/registerField";
import { validatePassword } from "../utils/validatePassword";

const PwChangePage = ({ blok, user, token }) => {
  const { changePasswordResponse, changePasswordResult, changePassword } =
    useChangePassword(token);

  const navigate = useNavigate();

  const t1 = useToggle();

  const t2 = useToggle();

  const t3 = useToggle();

  const [passwords, setPasswords] = useState({
    confirmOldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [logMessage, setLogMessage] = useState({
    isShown: false,
    text: "",
    bgColor: "",
    color: "",
  });

  useEffect(() => {
    /* console.log(changePasswordResponse, changePasswordResult); */
    if (changePasswordResponse?.ok) {
      setLogMessage({
        isShown: true,
        text: "Password modificata con successo!",
        bgColor: "transparent",
        color: "green",
      });
      setTimeout(() => navigate("/profile"), 3000);
    } else if (
      changePasswordResponse &&
      changePasswordResponse.status === 401
    ) {
      setLogMessage({
        isShown: true,
        text: "La vecchia password Inserita non è corretta",
        bgColor: "transparent",
        color: "red",
      });
    }
  }, [changePasswordResponse]);

  const handleSavePassword = () => {
    const { email } = user;
    const { confirmOldPassword, newPassword, confirmNewPassword } = passwords;
    const validate = validatePassword(newPassword, confirmNewPassword);
    /* console.log(validate.msg); */
    if (validate.isValid) {
      changePassword(
        email,
        confirmOldPassword,
        newPassword,
        confirmNewPassword
      );
    } else {
      setLogMessage({
        isShown: true,
        text: validate.msg,
        bgColor: "transparent",
        color: "red",
      });
    }
  };

  useEffect(() => {
    setTimeout(() => setLogMessage(), 3000);
  }, [logMessage]);

  const renderInputs = changePasswordInputs.map((item, key) => {
    const toggleHooks = [t1, t2, t3];
    const condition = toggleHooks[key][0];
    const toggleFunction = toggleHooks[key][1];
    return (
      <div key={key}>
        <input
          style={accessInput}
          name={item.name}
          type={
            item.type === "password"
              ? condition
                ? "text"
                : "password"
              : item.type
          }
          placeholder={
            passwords[item.name] === ""
              ? item.placeholder
              : passwords[item.name]
          }
          onChange={(e) => registerField(e, setPasswords)}
        />
        {condition ? (
          <AiFillEye style={eyeIcon} onClick={toggleFunction} />
        ) : (
          <AiFillEyeInvisible style={eyeIcon} onClick={toggleFunction} />
        )}
      </div>
    );
  });

  return (
    <div
      /* className={`wallpaper${isMobile ? "MB" : "DT"}`} */
      style={backgroundWrapper}
    >
      <p style={accessPageTitleStyle}>Reimposta la password </p>
      <div style={accessMainBox}>
        <img style={accessMainLogo} src="/images/3sy.png" />
        {renderInputs}
        <button style={accessButton} onClick={handleSavePassword}>
          <p style={accessButtonText}>Save</p>
        </button>
      </div>
      <Link style={forgotPwLink} to={"/recovery-password"}>
        <p style={accessLink}>Vecchia Password dimenticata?</p>
      </Link>
      {logMessage?.isShown && (
        <div
          style={{
            ...logBox,
            backgroundColor: logMessage.bgColor,
          }}
        >
          <p style={{ ...logText, color: `${logMessage?.color}` }}>
            {logMessage.text}
          </p>
        </div>
      )}
      <Footer />
    </div>
  );
};
export default PwChangePage;
