export const validatePassword = (password, confirmedPassword) => {
  let regExpWeak = /[a-z]/;
  let regExpMedium = /\d+/;
  let regExpStrong = /.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/;
  let inputWeek = password.match(regExpWeak);
  let inputMedium = password.match(regExpMedium);
  let inputStrong = password.match(regExpStrong);

  const condition1 = password === confirmedPassword;
  const condition2 = password.length >= 6;


  if (condition1) {
    if (condition2) {
      if (inputWeek && inputMedium && inputStrong) {
        return {
          isValid: true,
          securityLevel: "strong",
          msg: "Password Sicura.",
        };
      }
      if (
        (inputWeek && inputMedium) ||
        (inputMedium && inputStrong) ||
        (inputWeek && inputStrong)
      ) {
        return {
          isValid: true,
          securityLevel: "medium",
          msg: "Password Valida.",
        };
      }
      if (inputWeek || inputMedium || inputStrong) {
        return {
          isValid: false,
          securityLevel: "weak",
          msg: "La password deve contenere almeno un carattere maiuscolo e un numero.",
        };
      }
    } else {
      return {
        isValid: false,
        securityLevel: "not valid",
        msg: "La password deve avere minimo 6 caratteri.",
      };
    }
  } else {
    return {
      isValid: false,
      securityLevel: "not valid",
      msg: "La password deve combaciare.",
    };
  }
};
