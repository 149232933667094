import { isMobile } from "../consts/conditionals";
import { secondaryA, text } from "./palette";

export const userHeadBox = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  background: "#ffffff",

  gap: isMobile ? "10px" : "12.17px",
  padding: isMobile ? "20px 0px" : "24.3344px 0px",
  width: isMobile ? "323px" : "765px" /* 
  height: isMobile ? "136px" : "133.84px",   */,
  borderRadius: isMobile ? "10px" : "12.1672px",
};

export const userHeaderContent = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px",
  gap: isMobile ? "30px" : "111px" /* 
   width: isMobile ? "266px" : "379px",  
  height: isMobile ? "96px" : "114px",   */,
};
export const userImageBox = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "10px",
  /* 
  height: isMobile ? "96px" : "114px", */
  
};
export const imageSpot = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignContent: "center",
  textAlign: "center",

  width: isMobile ? "70px" : "88px",
  height: isMobile ? "70px" : "88px",

  background: "#000c1e",

  border: "3.65015px solid #fff",
  borderRadius: "100%", 
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat", 
};

export const modifyPhoto = {
 /*  width: "90px", */
  height: "16px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "133.02%",
  color: "#757575",
  cursor: "pointer",
};

export const imageShown = {
  borderRadius: "100%", 
};

export const imageInitialBox = {
  height: isMobile ? "37px" : "41px",
  width: isMobile ? "49px" : "62px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignContent: "center",
  textAlign: "center",
  margin: "auto",
};

export const imageInitials = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: isMobile ? "30px" : "36.5015px",
  lineHeight: "133.02%",

  color: "#f5f5f5",
};

export const fullNameSpot = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "133.02%",
  color: "#000000",

  fontSize: isMobile ? "15px" : "18.2508px",
  width: isMobile ? "146px" : "278px" /* 
  height: isMobile ? "20px" : "54px", */,
};

export const sectionTitleBox = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: isMobile ? "29px" : "35.20px",
  width: isMobile ? "323px" : "762px",
  height: isMobile ? "58px" : "50px",
};
export const sectionIcon = {
  width: isMobile ? "27.98px" : "34.04px",
  height: isMobile ? "28px" : "34.07px",
};
export const sectionIconBG = {
  padding: "1.8px",
  backgroundColor: "white",
  borderRadius: "100%",
};
export const sectionTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "133.02%",
  textlign: "center",
  color: "#ffffff",
  width: "fit-content",

  fontSize: isMobile ? "22px" : "26.7678px",
};

export const separator = {
  width: isMobile ? "323px" : "765px",
  height: "0px",
  border: "3.65015px solid #FFFFFF",
};

export const changePasswordLink = {
  textAlign: "center",
  marginTop: "10px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  width: isMobile ? "323px" : "765px",
  height: isMobile ? "15px" : "18px",
  fontSize: isMobile ? "18px" : "22px",
  lineHeight: "133.02%",
  color: "#757575",
  cursor: "pointer",
};

export const saveButton = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  width: isMobile ? "323px" : "765px",
  height: isMobile ? "47px" : "57.19px",

  background: secondaryA.hex,
  border: "3px solid #ffffff",
  borderRadius: isMobile ? "5px" : "6.08359px",
  cursor: "pointer",
};

export const saveButtonText = {
  width: isMobile ? "45px" : "55px",
  height: isMobile ? "17px" : "20px",
  fontFize: isMobile ? "15px" : "18.2508px",

  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "20px",
  color: text.hex,
};
