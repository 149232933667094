import { useState } from "react";
import { REGISTER } from "../api/endpointList";
import { apiURL } from "../consts/URLs";
import { fetchRequest } from "../utils/fetchRequest";
import { getOptions } from "../utils/getOption";

const useRegister = () => {
  const [registerResponse, setRegisterResponse] = useState();
  const [registerResult, setRegisterResult] = useState();

  const register = (name, email, type) => { 
    const url = `${apiURL}/${REGISTER}?name=${name}&email=${email}&role=${type}`;
    const options = getOptions({ method: "POST" });
    fetchRequest(url, options, setRegisterResponse, setRegisterResult);
  };

  return { registerResponse, registerResult, register };
};
export default useRegister;
