import { useEffect, useState } from "react";
import {
  profileInputsBank,
  profileInputsBio,
  profileInputsContract,
  profileInputsNames,
} from "../consts/profileInputs";

import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LogoutArea from "../components/LogoutArea";
import { isMobile } from "../consts/conditionals";
import { appHostURL } from "../consts/URLs";
import useAccount from "../hooks/useAccount";
import {
  backgroundWrapper,
  contentBox,
  inputBox,
  inputBoxNames,
  inputLabel,
  longInput,
  select,
  selectIcon,
  shortInput,
} from "../styles/contentGeneralStyle";
import {
  changePasswordLink,
  fullNameSpot,
  imageInitialBox,
  imageInitials,
  imageSpot,
  modifyPhoto,
  saveButton,
  saveButtonText,
  sectionIcon,
  sectionIconBG,
  sectionTitle,
  sectionTitleBox,
  separator,
  userHeadBox,
  userHeaderContent,
  userImageBox,
} from "../styles/profilePageStyle";
import "../styles/wallpaper.css";
import { setCookie } from "../utils/handleCookie";
import { formatDateGGMMYYYY, formatDateYYYYMMGG } from "../utils/reformatDate";
import { registerField } from "../utils/registerField";

const ProfilePage = ({
  blok,
  setUser,
  user,
  setAccountSelected,
  accountSelected,
  viewOnly,
  token,
}) => {
  const [RESPONSES, RESULTS, CTAs] = useAccount(token);

  console.log(accountSelected);

  const navigate = useNavigate();

  const normal = user.type === "normal";
  const manager = user.type === "manager";
  const admin = user.type === "admin";
  const [logMessage, setLogMessage] = useState();

  const [userDisplayed, setUserDisplayed] = useState({});

  const [profileImgBase64, setProfileImgBase64] = useState();

  const [srcProfileImagePreview, setSrcProfileImagePreview] = useState();

  const [modifiedUserInfo, setModifiedUserInfo] = useState({});

  const [modifiedContractInfo, setModifiedContractInfo] = useState({});

  const handleSelection = (e, setAction) => {
    registerField(e, setAction);
  };

  useEffect(() => {
    if (window.location.pathname === `/account-info`) {
      CTAs.getAccount(accountSelected.id);
    } else if (window.location.pathname === `/profile`) {
      CTAs.getAccount(user.id);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => setLogMessage(), 3000);
  }, [logMessage]);

  useEffect(() => {
    /* console.log(RESPONSES.getAccountResponse, RESULTS.getAccountResult);  */
    if (RESPONSES.getAccountResponse?.ok) {
      setUserDisplayed(RESULTS.getAccountResult.user);
    }
    if (
      window.location.pathname === `/profile` &&
      RESPONSES.getAccountResponse?.ok
    ) {
      setUser(RESULTS.getAccountResult.user);
      setCookie(
        "userEssential",
        JSON.stringify({
          id: RESULTS.getAccountResult.user.id,
          type: RESULTS.getAccountResult.user.type,
        })
      );
    } else if (
      window.location.pathname === `/account-info` &&
      RESPONSES.getAccountResponse?.ok
    ) {
      setAccountSelected(RESULTS.getAccountResult.user);
    }
  }, [RESPONSES.getAccountResponse]);

  useEffect(() => {
    /* console.log(RESULTS.modifyAccountResult, RESULTS.uploadImageResult); */
    if (RESPONSES.modifyAccountResponse?.ok) {
      setUserDisplayed(RESULTS.modifyAccountResult.user);
      CTAs.getAccount(userDisplayed.id);
      setLogMessage((prevMessage) => ({
        ...prevMessage,
        isShown: true,
        text: "Account aggiornato!",
        color: "lightGreen",
      }));
      if (profileImgBase64)
        CTAs.uploadImage(userDisplayed.id, profileImgBase64);
    } else if (
      RESPONSES.modifyAccountResponse &&
      RESPONSES.modifyAccountResponse.ok === false
    ) {
      setLogMessage((prevMessage) => ({
        ...prevMessage,
        isShown: true,
        text: "Account non aggiornato...riprovare",
        color: "lightGreen",
      }));
    }
  }, [RESPONSES.modifyAccountResponse]);

  const handleSaveInfo = () => {
    const allModifiedInfo = {
      ...modifiedUserInfo,
      ...modifiedContractInfo,
    };
    CTAs.modifyAccount(userDisplayed.id, allModifiedInfo);
  };

  const handleChangeImage = (e) => {
    const fileObj = e.target.files[0];
    setSrcProfileImagePreview((fileObj.src = URL.createObjectURL(fileObj)));

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      setProfileImgBase64(base64String);
    };
    reader.readAsDataURL(fileObj);
  };

  const handleChangePasswordRedirect = () => {
    navigate("/change-password");
  };

  const renderInputsNames = profileInputsNames.map((item, key) => {
    return (
      <div key={key} style={{ ...inputBox /*  ...inputBoxNames  */ }}>
        <p style={inputLabel}>{item.placeholder}</p>
        <input
          style={shortInput}
          name={item.name}
          type={item.type}
          disabled={viewOnly}
          defaultValue={userDisplayed[item.name]}
          placeholder={`Digita ${item.placeholder}`}
          onChange={(e) => registerField(e, setModifiedUserInfo)}
        />
      </div>
    );
  });

  const renderInputsBio = profileInputsBio.map((item, key) => {
    let bDate = "";
    if (userDisplayed[item.name] && item.name === "birthDate") {
      let tempDate = new Date(userDisplayed[item.name]);
      bDate = formatDateGGMMYYYY(tempDate);
    }
    return (
      <div key={key} style={inputBox}>
        <p style={inputLabel}>{item.placeholder}</p>
        <input
          style={longInput}
          name={item.name}
          type={item.type}
          disabled={viewOnly}
          defaultValue={
            item.name === "birthDate" ? `${bDate}` : userDisplayed[item.name]
          }
          placeholder={
            item.name === "birthDate"
              ? `${item.innerPlaceHolder}`
              : `Digita ${item.placeholder}`
          }
          onChange={(e) => registerField(e, setModifiedUserInfo)}
        />
      </div>
    );
  });

  const renderInputsBank = profileInputsBank.map((item, key) => {
    return (
      <div key={key} style={inputBox}>
        <p style={inputLabel}>{item.placeholder}</p>
        <input
          style={longInput}
          name={item.name}
          type={item.type}
          disabled={viewOnly}
          defaultValue={userDisplayed[item.name]}
          placeholder={`Digita ${item.placeholder}`}
          onChange={(e) => registerField(e, setModifiedUserInfo)}
        />
      </div>
    );
  });

  const renderInputsContract = profileInputsContract.map((item, key) => {
    return (
      <div key={key} style={inputBox}>
        <p style={inputLabel}>{item.placeholder}</p>
        <img style={selectIcon} src="/images/icons/select.png" />
        <select
          style={{ ...longInput, ...select }}
          name={item.name}
          type={item.type}
          placeholder={`Digita ${item.placeholder}`}
          value={userDisplayed[item.name]}
          disabled={normal || (viewOnly && normal)}
          onChange={(e) => {
            handleSelection(e, setModifiedContractInfo);
            handleSelection(e, setUserDisplayed);
          }}
        >
          {!userDisplayed[item.name] && <option>"Seleziona"</option>}
          <>
            {item.options.map((option, key2) => (
              <option key={key2}>{option.value}</option>
            ))}
          </>
        </select>
        {userDisplayed["contractType"] === "Partita iva" &&
          item.name === "contractType" && (
            <div style={inputBox}>
              <p style={inputLabel}>Partita Iva</p>
              <input
                style={longInput}
                name="partitaIva"
                type={"text"}
                /* disabled={normal || (viewOnly && normal)} */
                defaultValue={userDisplayed["partitaIva"]}
                placeholder={`Digita Partita iva`}
                onChange={(e) => registerField(e, setModifiedContractInfo)}
              />
            </div>
          )}
      </div>
    );
  });

  return (
    <div style={backgroundWrapper}>
      <Header type={user.type} isMobile={isMobile} />

      <div style={contentBox}>
        <div style={userHeadBox}>
          <div style={userHeaderContent}>
            <div style={userImageBox}>
              <div
                style={{
                  ...imageSpot,
                  backgroundImage: `url(${
                    srcProfileImagePreview
                      ? srcProfileImagePreview
                      : userDisplayed.profileImage
                  })`,
                }}
              >
                {userDisplayed.profileImage || srcProfileImagePreview ? (
                  <></>
                ) : (
                  <div style={imageInitialBox}>
                    <p style={imageInitials}>
                      {userDisplayed.name
                        ? userDisplayed.name[0].toUpperCase()
                        : "N"}
                      {userDisplayed.lastName
                        ? userDisplayed.lastName[0].toUpperCase()
                        : "C"}
                    </p>
                  </div>
                )}
              </div>
              {!viewOnly && (
                <label style={modifyPhoto} htmlFor="upload-photo">
                  Modifica Foto
                </label>
              )}
              <input
                style={{ display: "none" }}
                id="upload-photo"
                type="file"
                name="profileImg"
                placeholder="Immagine Profilo"
                disabled={viewOnly}
                accept="image/png, image/jpeg"
                onChange={handleChangeImage}
              />
            </div>
            <p style={fullNameSpot}>
              {userDisplayed.name ? userDisplayed.name : "Nome"}{" "}
              {userDisplayed.lastName ? userDisplayed.lastName : "Cognome"}
            </p>
          </div>
        </div>
        <div style={sectionTitleBox}>
          <img style={sectionIcon} src="/images/icons/personal.png" />
          <p style={sectionTitle}>Dati Personali</p>
        </div>
        <div style={inputBoxNames}>{renderInputsNames}</div>

        {admin || (manager && !viewOnly) ? (
          <div style={inputBox}>
            <p style={inputLabel}>{"Numero di telefono"}</p>
            <input
              style={longInput}
              name="telephoneNumber"
              type="text"
              disabled={viewOnly}
              placeholder={`Digita numero di telefono`}
              defaultValue={userDisplayed["telephoneNumber"]}
              onChange={(e) => registerField(e, setModifiedUserInfo)}
            />
          </div>
        ) : (
          renderInputsBio
        )}
        <div style={separator} />
        {(normal || manager || (viewOnly && userDisplayed.type != "admin")) && (
          <>
            <div style={sectionTitleBox}>
              <img
                style={{ ...sectionIcon, ...sectionIconBG }}
                src="/images/icons/bank.png"
              />
              <p style={sectionTitle}>Informazioni Bancarie</p>
            </div>
            {renderInputsBank}
            <div style={separator} />
            <div style={sectionTitleBox}>
              <img
                style={{ ...sectionIcon, ...sectionIconBG }}
                src="/images/icons/gear.png"
              />
              <p style={sectionTitle}>Amministrazione {"&"} Account</p>
            </div>
            {renderInputsContract}
          </>
        )}
        <div style={inputBox}>
          <p style={inputLabel}>{"Email"}</p>
          <input
            style={longInput}
            name="email"
            type="text"
            disabled={true}
            placeholder={`Digita email`}
            defaultValue={userDisplayed["email"]}
            onChange={(e) => registerField(e, setModifiedUserInfo)}
          />
        </div>
        {!viewOnly && (
          <p
            style={{ ...changePasswordLink, textDecoration: "underline" }}
            onClick={handleChangePasswordRedirect}
          >
            Cambia Password
          </p>
        )}
        <p
          style={{ color: `${logMessage?.color}` }}
          className={logMessage?.isShown && "document-logMessage"}
        >
          {logMessage?.text}
        </p>
        {(!viewOnly || (viewOnly && !normal)) && (
          <button style={saveButton} onClick={handleSaveInfo}>
            <p style={saveButtonText}>Salva</p>
          </button>
        )}
      </div>
      <div
        style={{
          height: "100px",
          marginTop: "auto",
        }}
      ></div>
      {!viewOnly && <LogoutArea token={token} />}
      <Footer />
    </div>
  );
};
export default ProfilePage;
