export const documentFilter = (originalDocsArray, criteria) => {
  let result = [];

  const condition =
    criteria.filterStatus === "Tutti" || criteria.filterType === "Tutti";
  const subCondition1 =
    criteria.filterStatus !== "Tutti" && criteria.filterType === "Tutti";
  const subCondition2 =
    criteria.filterStatus === "Tutti" && criteria.filterType !== "Tutti";

  if (condition) {
    if (subCondition1) {
      result = originalDocsArray.filter((e) =>
        e.status.includes(criteria.filterStatus)
      );
    } else if (subCondition2) {
      result = originalDocsArray.filter((e) =>
        e.document_type.includes(criteria.filterType)
      );
    }
  } else {
    result = originalDocsArray.filter(
      (e) =>
        e.status.includes(criteria.filterStatus) &&
        e.document_type.includes(criteria.filterType)
    );
  }
  return result;
};
