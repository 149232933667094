export const REGISTER = "register" 
export const LOGIN = "login"
export const LOGOUT = "logout"
export const FORGOT_PASSWORD = "forgot-password"
export const RESET_PASSWORD = "reset-password"
export const CHANGE_PASSWORD = "change-password"
export const ACCOUNTS = "accounts" 
export const UPLOAD_IMAGE = "image"  
export const SUBMISSIONS = "submissions"
export const UPLOAD_DOC = "document" 
