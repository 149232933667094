export const fetchRequest = async (
  url,
  options,
  setActionResponse,
  setActionResult
) => {
  try {
    const response = await fetch(url, options);
    const result = await response.json();
    setActionResponse(response);
    setActionResult(result);
  } catch (error) {
    setActionResponse(error);
  }
};
