import { useEffect, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { isMobile } from "../consts/conditionals";
import {
  accountBox,
  accountInfo,
  accountMenuIcon,
  buttonLink,
  buttonLinkBox,
  buttonLinkText,
  roleText,
  selectorIcon,
} from "../styles/accountPageStyle";
import {
  fullNameSpot,
  imageInitials,
  imageShown,
  imageSpot,
  userHeadBox,
  userHeaderContent,
} from "../styles/profilePageStyle";
import { getUserType } from "../utils/getUserType";
import { getCookie, setCookie } from "../utils/handleCookie";

const Account = ({
  blok,
  account,
  userType,
  setAccountSelected,
  isSelectorToggled,
  accountToBeRemovedKeys,
  handleSelectionAccounts,
  index,
}) => {
  const navigate = useNavigate();

  const [accountSelectedKey, setAccountSelectedKey] = useState();

  const openAccountMenu = (key) => {
    if (key === accountSelectedKey) setAccountSelectedKey();
    else setAccountSelectedKey(key);
  };

  const handleRedirect = (location, accountToBeSet) => {
    setAccountSelected(accountToBeSet);
    setCookie("accountSelectedId", accountToBeSet.id);
    navigate(location);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "30px",
      }}
    >
      <div
        style={{
          ...accountBox,
          backgroundColor: "white",
        }}
      >
        <div style={{ ...accountInfo }}>
          <div
            style={{
              ...userHeadBox,
              padding: isSelectorToggled ? "20px 10px" : userHeadBox.padding,
            }}
          >
            {isSelectorToggled && (
              <img
                style={{ ...selectorIcon, width: isMobile ? "20px" : "25px" }}
                src={`images/icons/selector${
                  accountToBeRemovedKeys.includes(index) ? "ON" : "OFF"
                }.png`}
                onClick={() => handleSelectionAccounts(index)}
              />
            )}
            <div style={userHeaderContent}>
              <div>
                <div
                  style={{
                    ...imageSpot,
                    backgroundImage: `url(${account.profileImage})`,
                  }}
                >
                  {account.profileImage ? (
                    <></>
                  ) : (
                    <p style={imageInitials}>
                      {account.name ? account.name[0].toUpperCase() : ""}
                      {account.lastName
                        ? account.lastName[0].toUpperCase()
                        : ""}
                    </p>
                  )}
                </div>
              </div>
              <div>
                <p style={fullNameSpot}>
                  {account.name} {account.lastName}
                </p>
                <p style={roleText}>{getUserType(account.type)}</p>
              </div>
            </div>
            <BsThreeDots
              style={{
                ...accountMenuIcon,
                backgroundColor: accountSelectedKey === index ? "#022675" : "",
                color: accountSelectedKey === index ? "#FFFFFF" : "",
              }}
              onClick={() => openAccountMenu(index)}
            />
          </div>
        </div>
      </div>
      {accountSelectedKey === index && (
        <div style={buttonLinkBox}>
          <div
            style={buttonLink}
            onClick={() => handleRedirect("/account-info", account)}
          >
            <img src="/images/icons/info.png" />
            <p style={buttonLinkText}>Info Utente</p>
          </div>
          <div
            style={buttonLink}
            onClick={() => handleRedirect("/documents", account)}
          >
            <img src="/images/icons/doc.png" />
            <p style={buttonLinkText}>Documenti</p>
          </div>
        </div>
      )}
    </div>
  );
};
export default Account;
