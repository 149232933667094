export const filterSelectInputs = [
  {
    name: "status",
    placeholder: "Stato",
    options: [
      {
        value: "Tutti",
        placeholder: "Tutti",
      },
      {
        value: "valid",
        placeholder: "Valido",
      },
      {
        value: "document required",
        placeholder: "Documento Richiesto",
      },
      {
        value: "signature required",
        placeholder: "Firma Richiesta",
      },
      {
        value: "in approval",
        placeholder: "In fase di Approvazione",
      },
      {
        value: "rejected",
        placeholder: "Rifiutato",
      },
    ],
  },
  {
    name: "document_type",
    placeholder: "Tipo",
    options: [
      {
        value: "Tutti",
        placeholder: "Tutti",
      },
      {
        value: "INBX",
        placeholder: "INBX - Documento Generico",
      },
      {
        value: "FX",
        placeholder: "FX - Fattura",
      },
      {
        value: "CNT",
        placeholder: "CNT - Contratto prestazione occasionale",
      },
      {
        value: "RCP",
        placeholder: "RCP - Ricevuta comunicazione preventiva",
      },
      {
        value: "IDEN",
        placeholder: "IDEN - Documento di identità",
      },
      {
        value: "CF",
        placeholder: "CF - Codice Fiscale",
      },
      {
        value: "RD",
        placeholder: "RD - Ritenuta d'acconto",
      },
      {
        value: "BP",
        placeholder: "BP - Busta paga",
      },
      {
        value: "CT",
        placeholder: "CT - Contratto",
      },
      {
        value: "AC",
        placeholder: "AC - Allegato Contratto",
      },
    ],
  },
];
