import { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { isMobile } from "../consts/conditionals";
import { dcoumentTypes, documentStatuses } from "../consts/statuses_and_types";
import useDocument from "../hooks/useDocument";
import useToggle from "../hooks/useToggle";
import {
  adminButtonApprove,
  adminButtonChioceText,
  adminButtonReject,
  adminButtonsBox,
  buttonApproval,
  buttonApprovalText,
  buttonLoad,
  buttonLoadText,
  dateLabel,
  docBox,
  docInfo,
  docName,
  download,
  noteLabel,
  typeLabel,
} from "../styles/documentPageStyle";
import { formatDateGGMMYYYY } from "../utils/reformatDate";

const possible_statuses = documentStatuses;
const possible_dcoumentTypes = dcoumentTypes;

const Document = ({
  blok,
  user,
  document,
  status,
  docApprovalSelectedKey,
  setDocApprovalSelectedKey,
  index,
  handleOpenLoadModal,
  setIsNotesModalOpen,
  accountSelected,
  token,
  reloadDocs,
  setLogMessage,
}) => {
  const { RESPONSES, RESULTS, CTAs } = useDocument(token);


  const [isToggled, toggle] = useToggle(false);

  const normal = user.type === "normal";
  const manager = user.type === "manager";
  const admin = user.type === "admin";

  const handleApprove = (document) => {
    const accountId = accountSelected.id;
    const documentId = document.id;
    CTAs.modifyDocument(accountId, documentId, {
      status: possible_statuses.valid,
    });
    setDocApprovalSelectedKey();
    setTimeout(() => reloadDocs(), 3000);
  };

  const handleReject = (document) => {
    setIsNotesModalOpen(true);
  };

  const handleDownload = () => {
    /* console.log(document); */
    window.open(document.document_path);
  };

  const handleDelete = () => {
    const accountId = accountSelected.id;
    const documentId = document.id;
    CTAs.deleteDocument(accountId, documentId);
  };
  useEffect(() => {
    /* console.log(RESPONSES.deleteDocumentResponse, RESULTS.deleteDocumentResult); */
    if (RESPONSES.deleteDocumentResponse?.ok) {
      reloadDocs();
      setLogMessage((prevMessage) => ({
        ...prevMessage,
        isShown: true,
        text: "Documento eliminato con successo!",
        color: "lightgreen",
      }));
    } else if (
      RESPONSES.deleteDocumentResponse &&
      RESPONSES.deleteDocumentResponse.ok === false
    ) {
      setLogMessage((prevMessage) => ({
        ...prevMessage,
        isShown: true,
        text: "Richiesta non andata a buon fine...riprovare ",
        color: "orange",
      }));
    }
  }, [RESPONSES.deleteDocumentResponse]);

  const toggleButtonBox = (key) => {
    if (key === docApprovalSelectedKey) setDocApprovalSelectedKey();
    else setDocApprovalSelectedKey(key);
  };

  const renderDocumentType = () => {
    let docType = "";
    for (let i = 0; i < possible_dcoumentTypes.length; i++) {
      if (possible_dcoumentTypes[i].includes(document.document_type.trim())) {
        docType = possible_dcoumentTypes[i];
      }
    }
    return docType;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          ...docBox,
          marginBottom:
            docApprovalSelectedKey === index
              ? isMobile
                ? "20px"
                : "80px"
              : isMobile
              ? "-40px"
              : "-20px",
        }}
      >
        <p style={docName}>{document.document_name}</p>
        <div style={docInfo}>
          <p style={typeLabel}>{renderDocumentType()}</p>
          <p style={dateLabel}>
            Emissione: {formatDateGGMMYYYY(new Date(document.created_at))}
          </p>
        </div>
        {status}
        {document.notes && (
          <div style={{ overflowY: "hidden", height: "fit-content" }}>
            <p style={noteLabel}>Note: {document.notes}</p>
          </div>
        )}

        {(document.status === possible_statuses.document_required ||
          document.status === possible_statuses.signature_required ||
          document.status === possible_statuses.rejected) &&
          normal && (
            <button
              style={buttonLoad}
              onClick={() => handleOpenLoadModal(document)}
            >
              <p style={buttonLoadText}>Carica Documento</p>
            </button>
          )}
        {document.status === possible_statuses.in_approval &&
          (admin || manager) && (
            <button
              style={buttonApproval}
              onClick={() => toggleButtonBox(index)}
            >
              <p style={buttonApprovalText}>Approva/Rifiuta Documento</p>
            </button>
          )}
      </div>
      {docApprovalSelectedKey === index && (
        <div style={adminButtonsBox}>
          <div
            style={adminButtonApprove}
            onClick={() => handleApprove(document)}
          >
            <p style={adminButtonChioceText}>Approva</p>
          </div>
          <div style={adminButtonReject} onClick={() => handleReject(document)}>
            <p style={adminButtonChioceText}>Rifiuta</p>
          </div>
        </div>
      )}

      <div
        style={{
          ...download,
          transform:
            docApprovalSelectedKey === index
              ? isMobile
                ? "translatex(155px) translatey(-60px)"
                : "translatex(325px) translatey(-140px)"
              : download.transform,
        }}
      >
        <div
          style={{
            position: "absolute",
            transform: isMobile
              ? `translatex(${isToggled ? "-18px" : "0"}) translatey(-135px)`
              : `translatex(${isToggled ? "-25px" : "0"}) translatey(-165px)`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!normal && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                backgroundColor: "red",
                borderRadius: "100%",
                padding: "5px",
                height: "21px",
                width: "21px",
              }}
            >
              <AiFillDelete
                style={{
                  fontSize: "large",
                  color: "white",
                  height: "21px",
                  width: "21px",
                }}
                src="/images/icons/download.png"
                onClick={toggle}
              />
            </div>
          )}
          {isToggled && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <button
                style={{
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  backgroundColor: "blue",
                  height: "20px",
                }}
                onClick={toggle}
              >
                <p
                  style={{
                    fontSize: isMobile ? "10px" : "15px",
                    color: "#fff",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "500",
                  }}
                >
                  ANNULLA
                </p>
              </button>
              <button
                style={{
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  backgroundColor: "darkRed",
                  height: "20px",
                }}
                onClick={handleDelete}
              >
                <p
                  style={{
                    fontSize: isMobile ? "10px" : "15px",
                    color: "#fff",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "500",
                  }}
                >
                  ELIMINA
                </p>
              </button>
            </div>
          )}
        </div>
        {document.document_path && (
          <img
            src="/images/icons/download.png"
            id="downloadBtn"
            onClick={handleDownload}
          />
        )}
      </div>
    </div>
  );
};
export default Document;
