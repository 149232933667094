import { useState } from "react";
import { FORGOT_PASSWORD, LOGOUT } from "../api/endpointList";
import { apiURL } from "../consts/URLs";
import { fetchRequest } from "../utils/fetchRequest";
import { getOptions } from "../utils/getOption";

const useRecoveryPassord = () => {
  const [recoveryPasswordResponse, setRecoveryPasswordResonse] = useState();
  const [recoveryPasswordResult, setRecoveryPasswordResult] = useState();

  const recoveryPassword = (email) => {
    const url = `${apiURL}/${FORGOT_PASSWORD}?email=${email}`;
    const options = getOptions({ method: "POST" });
    fetchRequest(
      url,
      options,
      setRecoveryPasswordResonse,
      setRecoveryPasswordResult
    );
  };

  return { recoveryPasswordResponse, recoveryPasswordResult, recoveryPassword };
};
export default useRecoveryPassord;
