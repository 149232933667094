import { useEffect, useState } from "react";

import Footer from "../components/Footer";
import { isMobile } from "../consts/conditionals";
import useRecoveryPassord from "../hooks/useRecoveryPassowrd";
import {
  accessButton,
  accessButtonText,
  accessInput,
  accessMainBox,
  accessMainLogo,
  accessPageTitleStyle,
  backgroundWrapper,
  recoveryPassowrdInstructions,
} from "../styles/accessGeneralStyle";
import "../styles/wallpaper.css";

const PwRecoveryPage = ({ blok }) => {
  const { recoveryPasswordResponse, recoveryPasswordResult, recoveryPassword } =
    useRecoveryPassord();

  const [email, setEmail] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);

  useEffect(() => {
    /* console.log(recoveryPasswordResponse, recoveryPasswordResult); */
    if (recoveryPasswordResponse?.ok) {
      setIsEmailSent(true);
    }
  }, [recoveryPasswordResponse]);

  const handleRecovery = () => {
    if (email !== "") {
      recoveryPassword(email);
    }
  };

  return (
    <div
      /* className={`wallpaper${isMobile ? "MB" : "DT"}`} */
      style={backgroundWrapper}
    >
      <p style={accessPageTitleStyle}>Password dimenticata?</p>
      <div style={{ ...accessMainBox }}>
        <img style={accessMainLogo} src="/images/3sy.png" />

        {isEmailSent ? (
          <p style={recoveryPassowrdInstructions}>
            Apri il link ricevuto via email
            <br />
            per accedere alla pagina per
            <br />
            reimpostare la password.
          </p>
        ) : (
          <input
            style={accessInput}
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        )}
        {isEmailSent ? (
          <button
            style={accessButton}
            onClick={() => window.open("https://mail.google.com/mail")}
          >
            <p style={accessButtonText}>Apri Mail</p>
          </button>
        ) : (
          <button
            style={accessButton}
            className="accessButton"
            onClick={handleRecovery}
          >
            <p style={accessButtonText}>Recupera Password</p>
          </button>
        )}
      </div>

      <Footer />
    </div>
  );
};
export default PwRecoveryPage;
