import { useState } from "react";
import { ACCOUNTS } from "../api/endpointList";
import { apiURL } from "../consts/URLs";
import { fetchRequest } from "../utils/fetchRequest";
import { getOptions } from "../utils/getOption";

const useAccountsList = (token) => {
  const [accountsListResponse, setAccountsListResponse] = useState();
  const [accountsListResult, setAccountsListResult] = useState();

  const getAccountsList = () => {
    const url = `${apiURL}/${ACCOUNTS}`;
    const options = getOptions({ method: "GET", token: token });
    fetchRequest(url, options, setAccountsListResponse, setAccountsListResult);
  };

  return { accountsListResponse, accountsListResult, getAccountsList };
};
export default useAccountsList;
