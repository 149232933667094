export const primary = {
  hex: "#0053FF",
};
export const secondaryA = {
  hex: "#4D88FF",
};
export const secondaryB = {
  hex: "#3e3e3e",
};
export const background = {
  hex: "#000000",
};
export const text = {
  hex: "#FFFFFF",
};
