import { useEffect, useState } from "react";
import { BsExclamationLg } from "react-icons/bs";
import Document from "../components/Document";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { isMobile } from "../consts/conditionals";
import { filterSelectInputs } from "../consts/filterSelectInputs";
import { documentStatuses } from "../consts/statuses_and_types";
import useDocument from "../hooks/useDocument";
import useDocumentsList from "../hooks/useDocumentsList";
import {
  addButtonExtra,
  addNewDocIcon,
  backgroundWrapper,
  closeIcon,
  contentBox,
  displayOnModalOpen,
  inputBox,
  inputLabel,
  longButton,
  longbuttonText,
  longInput,
  modalBackground,
  modalButtonBox,
  modalButtonText,
  modalContainer,
  modalContentBox,
  modalDocInput,
  modalInputDimension,
  modalProgressBarContainer,
  modalProgressBarLevel,
  modalTitleBox,
  modalTitleText,
  modalUploadIcon,
  modalUploadInputBox,
  modalUploadText,
  modalUploadWrapper,
  pageTitle,
  select,
  selectIcon,
} from "../styles/contentGeneralStyle";
import {
  docSelectIcon,
  docSelectLabel,
  filterBox,
  filterInputsBox,
  filterLabel,
  notesModalInputBox,
  notesModalInputField,
  statusIconBackground,
  statusIconSize,
  statusInfo,
  statusLabel,
} from "../styles/documentPageStyle";

import useAccount from "../hooks/useAccount";
import "../styles/logMessage.css";
import { documentFilter } from "../utils/documentFilter";
import { registerField } from "../utils/registerField";

const possible_statuses = documentStatuses;

const DocumentsPage = ({
  blok,
  user,
  token,
  accountSelected,
  setAccountSelected,
}) => {
  const { documentsListResponse, documentsListResult, getDocumentsList } =
    useDocumentsList(token);
  const { RESPONSES, RESULTS, CTAs } = useDocument(token);
  const [accountRESPONSES, accountRESULTS, accountCTAs] = useAccount(token);

  const normal = user.type === "normal";
  const manager = user.type === "manager";
  const admin = user.type === "admin";

  const [userDocuments, setUserDocuments] = useState([]);
  const [docsDisplayed, setDocsDisplayed] = useState([]);

  const [loadedDocument, setLoadedDocument] = useState();
  const [loadedDocumentBase64, setLoadedDocumentBase64] = useState();

  const [newDocIsSignatureRequired, setNewDocIsSignatureRequired] =
    useState(false);

  const [widthLevel, setWidthLevel] = useState(0);
  const [progressBarLevel, setProgressBarLevel] = useState(0);

  const [docsIsReset, setDocsIsReset] = useState(false);

  const [filterStatus, setFilterStatus] = useState("");

  const [filterType, setFilterType] = useState("");

  const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);

  const [isNewDocModalOpen, setIsNewDocModalOpen] = useState(false);

  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);

  const [newNotes, setNewNotes] = useState("-");

  const [modifyWithNotes, setModifyWithNotes] = useState(false);

  const [docTreated, setDocTreated] = useState();

  const [docApprovalSelectedKey, setDocApprovalSelectedKey] = useState();

  const [logMessage, setLogMessage] = useState();

  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (accountRESPONSES.getAccountResponse?.ok) {
      setAccountSelected(accountRESULTS.getAccountResult.user);
    }
  }, [accountRESPONSES]);

  useEffect(() => {
    if (user.type !== "normal") {
      getDocumentsList(accountSelected.id);
      accountCTAs.getAccount(accountSelected.id);
    } else {
      getDocumentsList(user.id);
    }
  }, [docsIsReset]);

  useEffect(() => {
    /* console.log(documentsListResponse,documentsListResult)  */
    if (documentsListResponse?.ok) {
      setUserDocuments(documentsListResult.submissions);
      setDocsDisplayed(documentsListResult.submissions);
    }
  }, [documentsListResponse]);

  useEffect(() => {
    if (docsDisplayed) setDocTreated(docsDisplayed[docApprovalSelectedKey]);
  }, [docApprovalSelectedKey]);

  useEffect(() => {
    const condition1 = filterStatus === "Tutti" && filterType === "Tutti";
    const condition2 = filterStatus === "" && filterType === "";
    if (docsDisplayed) {
      if (condition1 || condition2) {
        resetFilter();
      } else {
        setDocsDisplayed(
          documentFilter(userDocuments, { filterStatus, filterType })
        );
      }
    }
  }, [filterStatus, filterType]);

  const handleFilter = (e) => {
    const { name, value } = e.target;
    if (name === "status") {
      setFilterStatus(value);
    }
    if (name === "document_type") {
      setFilterType(value);
    }
  };

  const reloadDocs = () => {
    setDocsIsReset((prevState) => !prevState);
  };

  const resetFilter = () => {
    const matches = document.querySelectorAll("select");
    matches.forEach((element) => (element.selectedIndex = 0));
    setFilterStatus("");
    setFilterType("");
    reloadDocs();
  };

  const closeLoadModal = () => {
    setLoadedDocument();
    setLoadedDocumentBase64();
    setWidthLevel(0);
    setProgressBarLevel(0);
    setIsLoadModalOpen(false);
    reloadDocs();
    setDisableButton(false);
  };

  const closeNewDocModal = () => {
    setNewDocIsSignatureRequired(false);
    setLoadedDocument();
    setLoadedDocumentBase64();
    setWidthLevel(0);
    setProgressBarLevel(0);
    setIsNewDocModalOpen(false);
    reloadDocs();
    setDisableButton(false);
  };

  const openNewDocModal = () => {
    setIsNewDocModalOpen(true);
  };

  const closeNotesModal = () => {
    setLoadedDocument();
    setLoadedDocumentBase64();
    setIsNotesModalOpen(false);
    setNewNotes("-");
    setDocApprovalSelectedKey();
    reloadDocs();
    setDisableButton(false);
  };

  useEffect(() => {
    /* console.log(RESPONSES.uploadDocument, RESULTS.uploadDocumentResult); */
    if (RESPONSES.uploadDocumentResponse?.ok) {
      setLogMessage((prevMessage) => ({
        ...prevMessage,
        isShown: true,
        text: "Documento aggiunto con successo!",
        color: "green",
      }));
      closeLoadModal();
      closeNewDocModal();
      closeNotesModal();
    } else if (RESPONSES.uploadDocumentResponse?.ok === false) {
      setLogMessage((prevMessage) => ({
        ...prevMessage,
        isShown: true,
        text: "Richiesta non andata a buon fine...riprovare ",
        color: "orange",
      }));
      closeLoadModal();
      closeNewDocModal();
      closeNotesModal();
    }
  }, [RESPONSES.uploadDocumentResponse]);

  useEffect(() => {
    /* console.log(RESPONSES.createDocumentResponse, RESULTS.createDocumentResult); */
    if (RESPONSES.createDocumentResponse?.ok) {
      if (loadedDocumentBase64) {
        CTAs.uploadDocument(
          accountSelected.id,
          RESULTS.createDocumentResult.submission.id,
          loadedDocument.status,
          loadedDocumentBase64
        );
      } else {
        setLogMessage((prevMessage) => ({
          ...prevMessage,
          isShown: true,
          text: "Richiesta effettuata con successo",
          color: "green",
        }));
        closeLoadModal();
        closeNewDocModal();
        closeNotesModal();
      }
    } else if (RESPONSES.createDocumentResponse?.ok === false) {
      setLogMessage((prevMessage) => ({
        ...prevMessage,
        isShown: true,
        text: "Richiesta non andata a buon fine...riprovare compilando tutti i campi",
        color: "orange",
      }));
      closeLoadModal();
      closeNewDocModal();
      closeNotesModal();
    }
  }, [RESPONSES.createDocumentResponse]);

  useEffect(() => {
    /* console.log(RESPONSES.modifyDocumentResponse, RESULTS.modifyDocumentResult); */
    if (RESPONSES.modifyDocumentResponse?.ok) {
      if (modifyWithNotes) {
        setModifyWithNotes(false);
        CTAs.modifyDocument(accountSelected.id, docTreated.id, {
          status: possible_statuses.rejected,
        });
        closeNotesModal();
      }
      setLogMessage((prevMessage) => ({
        ...prevMessage,
        isShown: true,
        text: "Documento aggiornato con successo!",
        color: "green",
      }));
    } else if (RESPONSES.modifyDocumentResponse?.ok === false) {
      setLogMessage((prevMessage) => ({
        ...prevMessage,
        isShown: true,
        text: "Richiesta non andata a buon fine...riprovare ",
        color: "orange",
      }));
    } 
    reloadDocs();
  }, [RESPONSES.modifyDocumentResponse]);


  const handleNotesRequest = () => {
    setDisableButton(true); 
    CTAs.modifyDocument(accountSelected.id, docTreated.id, {
      notes: newNotes,
    });
    setModifyWithNotes(true);
  };

  const sendDocumentRequest = () => {
    setDisableButton(true); 
    CTAs.createDocument(accountSelected.id, {
      ...loadedDocument,
    });
  };

  const sendDocumentFile = () => {
    setDisableButton(true);
    if (loadedDocumentBase64) {
      CTAs.uploadDocument(
        user.id,
        loadedDocument.id,
        possible_statuses.in_approval,
        loadedDocumentBase64
      );
    }
  };

  useEffect(() => {
    setTimeout(() => setLogMessage(), 3000);
  }, [logMessage]);

  const toBase64 = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        setLoadedDocumentBase64(base64String);
      };
      reader.readAsDataURL(file);
      setProgressBarLevel(100);
    }
  };

  const handleLoadDocument = (e) => {
    const file = e.target.files[0];
    setProgressBarLevel(50);
    toBase64(file);
  };

  useEffect(() => {
    if (progressBarLevel !== 0 && widthLevel <= progressBarLevel - 2) {
      setInterval(
        setWidthLevel((prevLevel) => prevLevel + 2),
        1
      );
    }
  }, [progressBarLevel, widthLevel]);

  const renderDocumentStatusIcon = (document) => {
    if (document.status === possible_statuses.valid) {
      return (
        <div style={statusInfo}>
          <img style={statusIconBackground} src="/images/icons/valido.png" />
          <p
            style={{
              ...statusLabel,
              color: "#0053FF",
            }}
          >
            Documento valido
          </p>
        </div>
      );
    }
    if (document.status === possible_statuses.document_required) {
      return (
        <div style={{ ...statusInfo, flexDirection: "columns" }}>
          <div style={{ ...statusIconBackground, backgroundColor: "#FF7A00" }}>
            <BsExclamationLg
              style={{
                ...statusIconSize,
                color: "white",
              }}
            />
          </div>
          <p
            style={{
              ...statusLabel,
              textDecoration: "underline",
              color: "#FF7A00",
            }}
          >
            Documento richiesto
          </p>
        </div>
      );
    }
    if (document.status === possible_statuses.signature_required) {
      return (
        <div style={statusInfo}>
          <div style={{ ...statusIconBackground, backgroundColor: "#AD00FF" }}>
            <img style={statusIconSize} src="/images/icons/daFirmare.png" />
          </div>
          <p
            style={{
              ...statusLabel,
              color: "#AD00FF",
              textDecoration: "underline",
            }}
          >
            Documento da firmare
          </p>
        </div>
      );
    }
    if (document.status === possible_statuses.in_approval) {
      return (
        <div style={statusInfo}>
          <div style={{ ...statusIconBackground, backgroundColor: "#10C22D" }}>
            <img
              style={statusIconSize}
              src="/images/icons/inApprovazione.png"
            />
          </div>
          <p
            style={{
              ...statusLabel,
              color: "#10C22D",
            }}
          >
            Documento in approvazione
          </p>
        </div>
      );
    }
    if (document.status === possible_statuses.rejected) {
      return (
        <div style={statusInfo}>
          <div style={statusIconBackground}>
            <img
              style={{
                height: isMobile ? "25px" : "30px",
                width: isMobile ? "25px" : "30px",
              }}
              src="/images/icons/rifiutato.png"
            />
          </div>
          <p
            style={{
              ...statusLabel,
              textDecoration: "underline",
              color: "#FF0000",
              fontWeight: "600",
            }}
          >
            Documento rifiutato
          </p>
        </div>
      );
    }
  };

  const upLoadBlokBox = (
    <div style={modalUploadWrapper}>
      <p style={inputLabel}>Caricamento Documento</p>
      <label style={modalUploadInputBox} htmlFor="docInput">
        <label htmlFor="docInput">
          <img style={modalUploadIcon} src="/images/icons/uploadDoc.png" />
        </label>
        <input
          style={modalDocInput}
          id="docInput"
          type="file"
          name="profileImg"
          accept="application/pdf"
          onChange={handleLoadDocument}
        />
        <label
          htmlFor="docInput"
          style={{
            ...modalUploadText,
            color: widthLevel === 100 ? "#14FF00" : "white",
          }}
        >
          {widthLevel === 100
            ? "Caricamento Completato"
            : "Seleziona Documento"}
        </label>
      </label>
      {progressBarLevel !== 0 && progressBarLevel === 100 && (
        <div style={modalProgressBarContainer}>
          <div
            style={{ ...modalProgressBarLevel, width: `${widthLevel}%` }}
          ></div>
        </div>
      )}
    </div>
  );

  const modalNotes = (
    <div style={modalBackground}>
      <img
        style={closeIcon}
        src="/images/icons/close.png"
        onClick={closeNotesModal}
      />
      <div style={{ ...modalContainer, width: "" }}>
        <div style={{ ...modalContentBox, gap: "20px", width: "" }}>
          <div style={modalTitleBox}>
            <p style={{ ...modalTitleText, fontSize: "20px" }}>
              Inserire note per il rifiuto?
            </p>
          </div>
          <div style={{ ...modalTitleBox, justifyContent: "left" }}>
            <p style={inputLabel}>Note</p>
          </div>
          <div style={{ ...notesModalInputBox, width: "" }}>
            <textarea
              style={{ ...notesModalInputField, outline: "none" }}
              name="notes"
              placeholder="Inserisci Note..."
              maxLength={200}
              onChange={(e) => setNewNotes(e.target.value)}
            />
          </div>
        </div>
        <button
          disabled={disableButton}
          style={{ ...modalButtonBox, width: "100%" }}
          onClick={handleNotesRequest}
        >
          <p style={modalButtonText}>Invia</p>
        </button>
      </div>
    </div>
  );

  const modalLoadDoc = (
    <div style={modalBackground}>
      <div style={modalContainer}>
        <img
          style={closeIcon}
          src="/images/icons/close.png"
          onClick={closeLoadModal}
        />
        <div style={modalContentBox}>
          <div style={modalTitleBox}>
            <img src="/images/icons/doc.png" />
            <p style={modalTitleText}>Carica Documento</p>
          </div>
          {(loadedDocument?.status === possible_statuses.document_required ||
            loadedDocument?.status === possible_statuses.signature_required ||
            loadedDocument?.status === possible_statuses.rejected) &&
            normal &&
            upLoadBlokBox}
        </div>
        <button
          disabled={disableButton}
          style={modalButtonBox}
          onClick={() => sendDocumentFile(user.id)}
        >
          <p style={modalButtonText}>Invia Documento</p>
        </button>
      </div>
    </div>
  );

  const renderNewDocInputs = filterSelectInputs.map((item, key) => (
    <div key={key}>
      <div style={inputBox}>
        <p style={inputLabel}>{item.placeholder}</p>
        <img
          style={{
            ...selectIcon,
            transform: isMobile
              ? "translatey(34.5px) translatex(12.36px)"
              : "translatey(46.5px) translatex(17.36px)",
          }}
          src="/images/icons/select.png"
        />
        <select
          style={{ ...longInput, ...select, ...modalInputDimension }}
          name={item.name}
          type={item.type}
          onChange={(e) => {
            if (
              item.name === "status" &&
              (e.target.value === possible_statuses.signature_required ||
                e.target.value === possible_statuses.valid)
            ) {
              setNewDocIsSignatureRequired(true);
            } else if (
              item.name === "status" &&
              (e.target.value !== possible_statuses.signature_required ||
                e.target.value !== possible_statuses.valid)
            ) {
              setNewDocIsSignatureRequired(false);
            }
            registerField(e, setLoadedDocument);
          }}
        >
          <option>Seleziona</option>
          {item.options.map((option, key2) => {
            if (
              (option.value === "Tutti" ||
                option.value === "in approval" ||
                option.value === "rejected") &&
              (admin || manager)
            ) {
            } else
              return (
                <option key={key2} value={option.value}>
                  {option.placeholder}
                </option>
              );
          })}
        </select>
      </div>
      {item.name === "status" && newDocIsSignatureRequired && upLoadBlokBox}
    </div>
  ));

  const modalNewDoc = (
    <div style={modalBackground}>
      <img
        style={closeIcon}
        src="/images/icons/close.png"
        onClick={closeNewDocModal}
      />
      <div style={modalContainer}>
        <form style={modalContentBox}>
          <div style={modalTitleBox}>
            <img src="/images/icons/doc.png" />
            <p style={modalTitleText}>Crea Documento</p>
          </div>
          <div style={inputBox}>
            <p style={inputLabel}>Nome Documento</p>
            <input
              style={{
                ...longInput,
                ...modalInputDimension,
                width: isMobile ? "" : "287px",
              }}
              name="document_name"
              placeholder="Inserisci Nome Documento..."
              onChange={(e) => registerField(e, setLoadedDocument)}
            />
          </div>
          {renderNewDocInputs}
          <div style={inputBox}>
            <p style={inputLabel}>Note</p>
            <input
              style={{
                ...longInput,
                ...modalInputDimension,
                width: isMobile ? "" : "287px",
              }}
              name="notes"
              placeholder="Inserisci Note..."
              onChange={(e) => registerField(e, setLoadedDocument)}
            />
          </div>
        </form>
        <button
          disabled={disableButton}
          style={modalButtonBox}
          onClick={sendDocumentRequest}
        >
          <p style={modalButtonText}>Richiedi Documento</p>
        </button>
      </div>
    </div>
  );

  const handleOpenLoadModal = (document) => {
    setLoadedDocument(document);
    setIsLoadModalOpen(true);
  };

  const renderDocs = docsDisplayed
    ? docsDisplayed.map((document, key) => {
        const status = renderDocumentStatusIcon(document);
        return (
          <Document
            key={key}
            blok={blok}
            user={user}
            document={document}
            status={status}
            isMobile={isMobile}
            docApprovalSelectedKey={docApprovalSelectedKey}
            setDocApprovalSelectedKey={setDocApprovalSelectedKey}
            index={key}
            handleOpenLoadModal={handleOpenLoadModal}
            setIsNotesModalOpen={setIsNotesModalOpen}
            accountSelected={accountSelected}
            token={token}
            reloadDocs={reloadDocs}
            setLogMessage={setLogMessage}
            disableButton={disableButton}
            setDisableButton={setDisableButton}
          />
        );
      })
    : "";

  const renderFilterInputs = filterSelectInputs.map((input, key) => (
    <div key={key} style={inputBox}>
      <img style={docSelectIcon} src="/images/icons/select.png" />
      <p style={docSelectLabel}>
        {input["name"] === "status" ? "Stato:" : "Tipo:"}
      </p>
      <select
        style={{
          ...longInput,
          ...select,
          paddingLeft: isMobile ? "90px" : "90px",
        }}
        name={input.name}
        type={input.type}
        onChange={handleFilter}
      >
        {input.options.map((option, key2) => {
          return (
            <option key={key2} value={option.value}>
              {option.placeholder}
            </option>
          );
        })}
      </select>
    </div>
  ));

  return (
    <div style={backgroundWrapper}>
      <Header type={user.type} isMobile={isMobile} />

      {isLoadModalOpen && modalLoadDoc}
      {isNewDocModalOpen && modalNewDoc}
      {isNotesModalOpen && modalNotes}
      <div
        style={
          isLoadModalOpen || isNewDocModalOpen || isNotesModalOpen
            ? { ...contentBox, ...displayOnModalOpen }
            : contentBox
        }
      >
        <p style={pageTitle}>
          Documenti
          {(admin || manager) &&
            ` di ${accountSelected?.name} ${accountSelected?.lastName}`}
        </p>
        <div style={filterBox}>
          <p style={filterLabel}>Filtra Per:</p>
          <div style={filterInputsBox}>{renderFilterInputs}</div>
          <p
            style={{
              ...filterLabel,
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={resetFilter}
          >
            Resetta Filtri
          </p>
        </div>

        {(admin || manager) && (
          <div
            style={{
              ...longButton,
              ...addButtonExtra,
              width: isMobile ? "369px" : "765px",
            }}
            onClick={openNewDocModal}
          >
            <img style={addNewDocIcon} src="images/icons/add.png" />
            <p style={{ ...longbuttonText }}>Aggiugni nuovo Documento</p>
          </div>
        )}
        <p
          style={{
            fontSize: isMobile ? "14px" : "18px",
            color: logMessage?.color,
          }}
          className={logMessage?.isShown && "document-logMessage"}
        >
          {logMessage?.text}
        </p>

        <div style={contentBox}>{renderDocs}</div>
      </div>
      <div
        style={{
          height: "100px",
          marginTop: "auto",
        }}
      ></div>
      <Footer />
    </div>
  );
};
export default DocumentsPage;

/* const testMocResponseDocs = [
  {
    id: 1,
    document_name: "docName1",
    document_type: "document",
    status: "valid",
    type: "INBX",
    document_path: "asdfasdfasdghfdsdfhdfsh",
    to_user: 27,
    last_update_from: 27,
    document_uploaded_at: null,
    from_user: 2,
    created_at: new Date("11/02/2020"),
    updated_at: null,
  },
  {
    id: 2,
    document_name: "docName2",
    document_type: "document",
    status: "in approval",
    notes: "note example 2",
    type: "FX",
    document_path: "asdfasdfasdghfdsdfhdfsh",
    to_user: 27,
    last_update_from: 27,
    document_uploaded_at: null,
    from_user: 2,
    created_at: new Date("2/05/2020"),
    updated_at: null,
  },
  {
    id: 3,
    document_name: "docName3",
    document_type: "document",
    status: "in approval",
    notes: "note example 3",
    type: "CF",
    document_path: "asdfasdfasdghfdsdfhdfsh",
    to_user: 27,
    last_update_from: 27,
    document_uploaded_at: null,
    from_user: 2,
    created_at: new Date("11/07/2020"),
    updated_at: null,
  },
  {
    id: 4,
    document_name: "docName4",
    document_type: "document",
    status: "document required",
    notes: "note example 4",
    type: "CNT",
    document_path: "asdfasdfasdghfdsdfhdfsh",
    to_user: 27,
    last_update_from: 27,
    document_uploaded_at: null,
    from_user: 2,
    created_at: new Date("12/02/2021"),
    updated_at: null,
  },
  {
    id: 5,
    document_name: "docName5",
    document_type: "document",
    status: "signature required",
    notes: "note example 5",
    type: "RCP",
    document_path: "asdfasdfasdghfdsdfhdfsh",
    to_user: 27,
    last_update_from: 27,
    document_uploaded_at: null,
    from_user: 2,
    created_at: new Date("5/27/2019"),
    updated_at: null,
  },
  {
    id: 6,
    document_name: "docName6",
    document_type: "document",
    status: "valid",
    notes: "note example 6",
    type: "IDEN",
    document_path: "asdfasdfasdghfdsdfhdfsh",
    to_user: 27,
    last_update_from: 27,
    document_uploaded_at: null,
    from_user: 2,
    created_at: new Date("1/22/2019"),
    updated_at: null,
  },
  {
    id: 7,
    document_name: "docName7",
    document_type: "document",
    status: "rejected",
    notes: "note example 7",
    type: "INBX",
    document_path: "asdfasdfasdghfdsdfhdfsh",
    to_user: 27,
    last_update_from: 27,
    document_uploaded_at: null,
    from_user: 2,
    created_at: new Date("7/16/2021"),
    updated_at: null,
  },
  {
    id: 8,
    document_name: "docName8",
    document_type: "document",
    status: "valid",
    notes: "note example 8",
    type: "FX",
    document_path: "asdfasdfasdghfdsdfhdfsh",
    to_user: 27,
    last_update_from: 27,
    document_uploaded_at: null,
    from_user: 2,
    created_at: new Date("1/26/2020"),
    updated_at: null,
  },
]; */
