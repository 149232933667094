export const mobileMenuBox = {
  position: "fixed",
  bottom: "0",
  right: "0",
  display: "flex",
  flexDirection: "column",
  justifyItems: "space-between",
  alignItems: "center",
  height: "100%",
  width: "100%",
  zIndex: "1",
  backgroundColor: "#000000",
};
export const closeIconWrapper = {
  display: "flex",
  justifyContent: "flex-end",
  width: "90%",
  margin: "30px auto",
};
export const closeIcon = {
  color: "white",
  fontSize: "47px",
  cursor:"pointer"
};
export const menuLogo = {
  width: "156px",
  height: "76px",
};
export const linkBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "17px",
  textAlign: "center",
  margin: "auto",
  cursor:"pointer"
};
export const link = {
  height: "33px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "30px",
  lineHeight: "33px",

  textAlign: "center",
};
