import { useState } from "react";
import { ACCOUNTS, SUBMISSIONS } from "../api/endpointList";
import { apiURL } from "../consts/URLs";
import { fetchRequest } from "../utils/fetchRequest";
import { getOptions } from "../utils/getOption";

const useDocumentsList = (token) => {
  const [documentsListResponse, setDocumentsListResponse] = useState();
  const [documentsListResult, setDocumentsListResult] = useState();

  const getDocumentsList = (id) => {
    const url = `${apiURL}/${ACCOUNTS}/${id}/${SUBMISSIONS}`;
    const options = getOptions({ method: "GET", token: token });
    fetchRequest(
      url,
      options,
      setDocumentsListResponse,
      setDocumentsListResult
    );
  };

  return { documentsListResponse, documentsListResult, getDocumentsList };
};
export default useDocumentsList;
