export const getOptions = ({ method, body, formData, token }) => {
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  if (token) {
    myHeaders.append("Authorization", `Bearer ${token}`);
  }
  if (body) {
    return {
      method: method,
      body: JSON.stringify(body),
      headers: myHeaders,
      redirect: "follow",
    };
  }
  if (formData) {
    return {
      method: method,
      body: formData,
      headers: myHeaders,
      redirect: "follow",
    };
  } else {
    return {
      method: method,
      headers: myHeaders,
      redirect: "follow",
    };
  }
};
