import { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import { resetPasswordInputs } from "../consts/authenticationPasswordInputs";
import { isMobile } from "../consts/conditionals";
import { privacyPolicyLink, termsAndConditionsLink } from "../consts/URLs";
import useLogin from "../hooks/useLogin";
import useResetPassword from "../hooks/useResetPassword";
import useToggle from "../hooks/useToggle";
import {
  accessButton,
  accessButtonText,
  accessInput,
  accessLink,
  accessMainBox,
  accessMainLogo,
  accessPageTitleStyle,
  backgroundWrapper,
  checkboxContainer,
  checkboxInput,
  conditionBox,
  eyeIcon,
  logBox,
  logText,
} from "../styles/accessGeneralStyle";
import "../styles/wallpaper.css";
import { registerField } from "../utils/registerField";
import { validatePassword } from "../utils/validatePassword";

const PwResetPage = ({ block, setUser, token, setToken }) => {
  const { resetPasswordResponse, resetPasswordResult, resetPassword } =
    useResetPassword();

  const { loginResponse, loginResult, login } = useLogin();

  const [firstAccess, setFirstAccess] = useState(false);
  const [tempToken, setTempToken] = useState();

  const navigate = useNavigate();

  const t1 = useToggle();
  const t2 = useToggle();

  const [userId, setUserId] = useState();
  const [email, setEmail] = useState();

  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  });

  const [conditions, setConditions] = useState({
    privacyPolicy: false,
    termsAndConditions: false,
  });

  const [logMessage, setLogMessage] = useState({
    isShown: false,
    text: "",
  });

  const handleCondition = (e) => {
    const { name, checked } = e.target;
    setConditions((prevConditions) => ({ ...prevConditions, [name]: checked }));
  };

  useEffect(() => {
    const url = new URL(window.location.href); 
    const isFirstAccess = url.searchParams.get("firstAccess");
    const urlEmail = url.searchParams.get("email");
    const tToken = url.searchParams.get("token");
    if (isFirstAccess && tToken && urlEmail) { 
      setTempToken(tToken);
      setEmail(urlEmail);
      setFirstAccess(isFirstAccess);
    }else if(!tToken){navigate("/login")}
  }, []);

  useEffect(() => {
    if (loginResponse && loginResponse.ok) {
      setToken(loginResult.token)
      setUser(loginResult.user);
      navigate("/profile");
    } else if (loginResponse && !loginResponse?.ok) navigate("/login");
  }, [loginResponse]);

  useEffect(() => {
    if (resetPasswordResponse?.ok) {
      const { password } = passwords;
      login(email, password);
    } else if (resetPasswordResponse && !resetPasswordResponse?.ok) {
      setLogMessage({
        isShown: true,
        text: resetPasswordResponse.statusText,
      });
    }
  }, [resetPasswordResponse]);

  const handleConfirm = () => {
    const { privacyPolicy, termsAndConditions } = conditions;
    const { password, confirmPassword } = passwords;
    const validate = validatePassword(password, confirmPassword);
    /* console.log(validate); */
    if (
      ((privacyPolicy && termsAndConditions) || !firstAccess) &&
      validate.isValid
    ) {
      resetPassword( 
        email,
        tempToken, 
        password,
        confirmPassword
      );
    } else if (validate.isValid === false) {
      setLogMessage({
        isShown: true,
        text: validate.msg,
      });
    } else if (!privacyPolicy || !termsAndConditions) {
      setLogMessage({
        isShown: true,
        text: "Accettare Privacy e Termini e Condizioni",
      });
    }
  };

  const renderInputs = resetPasswordInputs.map((item, key) => {
    const toggleHooks = [t1, t2];
    const condition = toggleHooks[key][0];
    const toggleFunction = toggleHooks[key][1];
    return (
      <div key={key}>
        <input
          style={accessInput}
          name={item.name}
          type={
            item.type === "password"
              ? condition
                ? "text"
                : "password"
              : item.type
          }
          placeholder={
            passwords[item.name] === ""
              ? item.placeholder
              : passwords[item.name]
          }
          onChange={(e) => registerField(e, setPasswords)}
        />
        {condition ? (
          <AiFillEye style={eyeIcon} onClick={toggleFunction} />
        ) : (
          <AiFillEyeInvisible style={eyeIcon} onClick={toggleFunction} />
        )}
      </div>
    );
  });

  return (
    <div
      /* className={`wallpaper${isMobile ? "MB" : "DT"}`} */
      style={backgroundWrapper}
    >
      <p style={accessPageTitleStyle}>Crea nuova password</p>
      <div style={accessMainBox}>
        <img style={accessMainLogo} src="/images/3sy.png" />
        {renderInputs}
        {firstAccess && (
          <div>
            <div style={conditionBox}>
              <div style={checkboxContainer}>
                <input
                  style={checkboxInput}
                  type={"checkbox"}
                  name="privacyPolicy"
                  onChange={handleCondition}
                />
                <p
                  style={{
                    ...accessLink,
                    width: "fit-content",
                    textDecoration: "underline",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                  onClick={() => window.open(privacyPolicyLink)}
                >
                  Accetta Privacy Policy
                </p>
              </div>
              <div style={checkboxContainer}>
                <input
                  style={checkboxInput}
                  type={"checkbox"}
                  name="termsAndConditions"
                  onChange={handleCondition}
                />
                <p
                  style={{
                    ...accessLink,
                    width: "fit-content",
                    textDecoration: "underline",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                  onClick={() => window.open(termsAndConditionsLink)}
                >
                  Accetta Termini e Condizioni
                </p>
              </div>
            </div>
          </div>
        )}
        <button style={accessButton} onClick={handleConfirm}>
          <p style={accessButtonText}>{firstAccess ? "Accedi" : "Salva"}</p>
        </button>
      </div>
      {logMessage.isShown && (
        <div style={logBox}>
          <p style={logText}>{logMessage.text}</p>
        </div>
      )}
      <Footer />
    </div>
  );
};
export default PwResetPage;
