import { isMobile } from "../consts/conditionals";
import { primary, secondaryA, secondaryB } from "./palette";

export const accountBox = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  /* 
  padding: isMobile ? "20px 10px" : "24.3344px 0px",  */
  /* 
  width: isMobile ? "323px" : "765px",   */
  /* 
  height: isMobile ? "110px" : "133.84px",  */
  gap: isMobile ? "10px" : "20px",
  borderRadius: isMobile ? "10px" : "12.1672px",

  background: "#FFFFFF",
};

export const accountInfo = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: isMobile ? "16px" : "30px",
};

export const deleteButton = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: isMobile ? "5px" : "10px",
  gap: isMobile ? "10px" : "10px",
  width: isMobile ? "61px" : "91px",
  height: isMobile ? "27px" : "44px",
  borderStyle: "hidden",
  borderRadius: "8px",
  cursor: "pointer",
};

export const deleteButtonText = {
  fontSize: isMobile ? "13px" : "18px",
  lineHeight: "133.02%",
  width: isMobile ? "51px" : "71px",
  height: isMobile ? "17px" : "24px",
  color: "#FFFFFF",
};

export const deleteAllSelectedButton = {
  alignItems: isMobile ? "flex-start" : "center",
  gap: isMobile ? "10px" : "30px",
  width: isMobile ? "323px" : "765px",
  height: isMobile ? "350px" : "625.36px",
};

export const selectorIcon = {
  cursor: "pointer",
};
export const buttonLinkBox = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  padding: "0px",
  gap: isMobile ? "10px" : "30px",

  width: isMobile ? "303px" : "465.48px",
  height: isMobile ? "66px" : "61px",
};
export const buttonLink = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "8px 10px",
  gap: isMobile ? "10px" : "15px",

  width: isMobile ? "145px" : "272.58px",
  height: isMobile ? "" : "42.75px",
  cursor: "pointer",

  background: "#3e3e3e",
  borderRadius: "10px",
};
export const buttonLinkText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: isMobile ? "13.9px" : "18px",
  lineHeight: "133.02%",
  color: "#ffffff",
};
export const roleText = {
  /* 
  width: "52px",
  height: "20px", */

  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: isMobile ? "12px" : "15px",
  lineHeight: "133.02%",

  color: "#000000",
};

export const accountMenuIcon = {
  fontSize: isMobile ? "x-large" : "xx-large",
  cursor: "pointer",
  borderRadius: "100%",
};
