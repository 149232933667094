import { isMobile } from "../consts/conditionals";
 

export const logoutBox = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  marginTop: "50px", 
  marginBottom: "50px",
  
  cursor: "pointer", 
  gap: "12px", 
}; 
export const logoutIcon = {
  width: "14px",
  height: "18px",
};
export const logoutText = {
  width: "60px",
  height: "20px",

  fontFamily: "Poppins",
  fontDtyle: "normal",
  fontWeight: "500",
  fontSize: "15px",
  lineHeight: "133.02%",

  textAlign: "center",

  color: "#ffffff",
};
