import { useEffect, useState } from "react";
import Account from "../components/Account";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { isMobile } from "../consts/conditionals";
import { profileInputsContract } from "../consts/profileInputs";
import useAccount from "../hooks/useAccount";
import useAccountsList from "../hooks/useAccountsList";
import useRegister from "../hooks/useRegister";
import useToggle from "../hooks/useToggle";
import { deleteButton, deleteButtonText } from "../styles/accountPageStyle";
import {
  backgroundWrapper,
  closeIcon,
  contentBox,
  displayOnModalOpen,
  inputBox,
  inputLabel,
  longButton,
  longbuttonText,
  modalBackground,
  modalButtonBox,
  modalButtonText,
  modalContentBox,
  modalInputDimension,
  modalTitleBox,
  modalTitleText,
  pageTitle,
  select,
  selectIcon,
  shortInput,
} from "../styles/contentGeneralStyle";
import "../styles/logMessage.css";
import { registerField } from "../utils/registerField";
const AccountsPage = ({ blok, user, setAccountSelected, token, viewOnly }) => {
  const { registerResponse, registerResult, register } = useRegister();
  const { accountsListResponse, accountsListResult, getAccountsList } =
    useAccountsList(token);
  const [RESPONSES, RESULTS, CTAs] = useAccount(token);

  const normal = user.type === "normal";
  const manager = user.type === "manager";
  const admin = user.type === "admin";


  const [isSelectorToggled, toggle] = useToggle();

  const [accountsList, setAccountsList] = useState();

  const [accountToBeRemovedKeys, setAccountToBeRemovedKeys] = useState([]);
  const [accountsToBeRemovedList, setAccountsToBeRemovedList] = useState([]);

  const [newAccountModalIsOpen, setNewAccountModalIsOpen] = useState(false);

  const [logMessage, setLogMessage] = useState();

  const [newAccount, setNewAccount] = useState();
  const [isPartitaIva, setIsPartitaIva] = useState(false);

  const [accountIsReset, setAccountIsReset] = useState(false);

  const userTypeOptions = admin
    ? [
        { value: 0, placeholder: "Normale" },
        { value: 1, placeholder: "Manager" },
      ]
    : [{ value: 0, placeholder: "Normale" }];

  useEffect(() => {
    getAccountsList();
  }, [registerResponse, isSelectorToggled, accountIsReset]);

  useEffect(() => {
    if (accountsListResponse?.ok) {
      setAccountsList(accountsListResult.users);
    }
  }, [accountsListResponse]);

  const handleSelectionAccounts = (key) => {
    setAccountToBeRemovedKeys((prevKeys) => {
      if (prevKeys.includes(key)) {
        let result = [];
        result = prevKeys.filter((e) => e !== key);
        return result;
      } else return [...prevKeys, key];
    });
  };

  useEffect(() => {
    if (!isSelectorToggled) {
      setAccountsToBeRemovedList([]);
      setAccountToBeRemovedKeys([]);
    }
  }, [isSelectorToggled]);

  useEffect(() => {
    let accountsHolded = [];
    accountToBeRemovedKeys.forEach((key) => {
      accountsHolded.push(accountsList[key]);
    });
    setAccountsToBeRemovedList(accountsHolded);
  }, [accountToBeRemovedKeys]);

  useEffect(() => {
    /* console.log(RESPONSES.deleteAccountResponse, RESULTS.deleteAccountResult); */
    if (RESPONSES.deleteAccountResponse?.ok) {
      setLogMessage((prevMessage) => ({
        ...prevMessage,
        isShown: true,
        text: "Account Eliminato!",
        color: "lightGreen",
      }));
    }
    setAccountIsReset((prevState) => !prevState);
  }, [RESPONSES.deleteAccountResponse]);

  const handleDeleteAccounts = () => {
    accountsToBeRemovedList.forEach((element) => {
      const { id } = element;
      CTAs.deleteAccount(id);
    });
    setAccountToBeRemovedKeys([]);
    setAccountsToBeRemovedList([]);
    toggle();
  };

  const openNewAccountModal = () => {
    setNewAccountModalIsOpen(true);
  };

  const closeNewAccountModal = () => {
    setNewAccount();
    setIsPartitaIva(false);
    setNewAccountModalIsOpen(false);
  };

  useEffect(() => {
    if (registerResponse?.ok) {
      console.log(registerResult, registerResponse);
      CTAs.modifyAccount(registerResult.user.id, newAccount); /* 
      setLogMessage((prevMessage) => ({
        ...prevMessage,
        isShown: true,
        text: "Account aggiunto con successo!",
      }));  */
    } else if (registerResponse?.ok === false) {
      setLogMessage((prevMessage) => ({
        ...prevMessage,
        isShown: true,
        text: "L'Account non è stato registrato...riprovare",
        color: "orange",
      }));
    }
    setAccountIsReset((prevState) => !prevState);
  }, [registerResponse]);

  useEffect(() => {
    if (RESPONSES.modifyAccountResponse?.ok) {
      setLogMessage((prevMessage) => ({
        ...prevMessage,
        isShown: true,
        text: "Account aggiornato con successo!",
      }));
      closeNewAccountModal();
    } else if (RESPONSES.modifyAccountResponse?.ok === false) {
      setLogMessage((prevMessage) => ({
        ...prevMessage,
        isShown: true,
        text: "L'Account è stato aggiunto ma non aggiornato...aggiornare tramite profilo utente",
        color: "orange",
      }));
      closeNewAccountModal();
    }
  }, [RESPONSES.modifyAccountResponse]);

  const handleRegisterNewAccount = (e) => {
    const condition1 =
      newAccount.name &&
      newAccount.email &&
      newAccount.type &&
      newAccount.contractType &&
      newAccount.userRole;
    const condition2 = newAccount.partitaIva !== undefined;
    const { email, type } = newAccount;

    if (condition1) {
      if ((isPartitaIva && condition2) || !isPartitaIva) {
        register(newAccount.name, email, type);
      } else {
        setLogMessage((prevMessage) => ({
          ...prevMessage,
          isShown: true,
          text: "Partita iva non puo' essere vuota",
          color: "orange",
        }));
        closeNewAccountModal();
      }
    }
  };

  useEffect(() => {
    setTimeout(() => setLogMessage(), 3000);
  }, [logMessage]);

  const renderUsers = accountsList?.map((account, key) => {
    return (
      <Account
        key={key}
        blok={blok}
        account={account}
        setAccountSelected={setAccountSelected}
        isSelectorToggled={isSelectorToggled}
        accountToBeRemovedKeys={accountToBeRemovedKeys}
        handleSelectionAccounts={handleSelectionAccounts}
        index={key}
        viewOnly={viewOnly}
      />
    );
  });

  const handleSelectionFields = (e, setAction) => {
    const { name, value } = e.target;
    if (value === "Partita iva" && name === "contractType") {
      setIsPartitaIva(true);
    } else if (value !== "Partita iva" && name === "contractType") {
      setIsPartitaIva(false);
    }
    registerField(e, setAction);
  };

  const renderInputsContract = profileInputsContract.map((item, key) => (
    <div key={key} style={inputBox}>
      <p style={inputLabel}>{item.placeholder}</p>
      <img
        style={{
          ...selectIcon,
          transform: isMobile
            ? "translatey(34.5px) translatex(12.36px)"
            : "translatey(48.5px) translatex(17.36px)",
        }}
        src="/images/icons/select.png"
      />
      <select
        style={{ ...shortInput, ...select, ...modalInputDimension }}
        name={item.name}
        type={item.type}
        onChange={(e) => handleSelectionFields(e, setNewAccount)}
      >
        <option>Seleziona</option>
        {item.name === "userRole" ? (
          <>
            {item.options.map((option, key2) => (
              <option key={key2}>{option.value}</option>
            ))}
          </>
        ) : (
          <>
            {item.options.map((option, key2) => (
              <option key={key2}>{option.value}</option>
            ))}
          </>
        )}
      </select>
      {isPartitaIva && item.name === "contractType" && (
        <div style={inputBox}>
          <p style={inputLabel}>Partita Iva</p>
          <input
            style={{
              ...shortInput,
              ...modalInputDimension,
              width: isMobile ? "" : "286px",
            }}
            name="partitaIva"
            type="text"
            placeholder={"Digita Partita iva"}
            onChange={(e) => registerField(e, setNewAccount)}
          />
        </div>
      )}
    </div>
  ));

  const modalNewAccount = (
    <div style={modalBackground}>
      <img
        style={closeIcon}
        src="/images/icons/close.png"
        onClick={closeNewAccountModal}
      />
      <div style={{ ...modalContentBox, gap: isMobile ? "22px" : "25px" }}>
        <div style={modalTitleBox}>
          <img src="/images/icons/personal.png" />
          <p style={modalTitleText}>Aggiungi Utente</p>
        </div>
        <div style={inputBox}>
          <p style={inputLabel}>Nome:</p>
          <input
            style={{
              ...shortInput,
              ...modalInputDimension,
              width: isMobile ? "" : "285px",
            }}
            name="name"
            type="text"
            placeholder="Digita nome"
            onChange={(e) => registerField(e, setNewAccount)}
          />
          <p style={inputLabel}>Email:</p>
          <input
            style={{
              ...shortInput,
              ...modalInputDimension,
              width: isMobile ? "" : "285px",
            }}
            name="email"
            type="mail"
            placeholder="Digita email"
            onChange={(e) => registerField(e, setNewAccount)}
          />
          <div style={inputBox}>
            <p style={inputLabel}>Tipo Utente</p>
            <img
              style={{
                ...selectIcon,
                transform: isMobile
                  ? "translatey(34.5px) translatex(12.36px)"
                  : "translatey(48.5px) translatex(17.36px)",
              }}
              src="/images/icons/select.png"
            />
            <select
              style={{ ...shortInput, ...select, ...modalInputDimension }}
              name="type"
              type="text"
              onChange={(e) => registerField(e, setNewAccount)}
            >
              <option>Seleziona</option>
              {userTypeOptions.map((option, key) => {
                return (
                  <option key={key} value={option.value}>
                    {option.placeholder}
                  </option>
                );
              })}
            </select>
          </div>
          {renderInputsContract}
        </div>
        <button style={modalButtonBox} onClick={handleRegisterNewAccount}>
          <p style={modalButtonText}>Aggiungi Utente</p>
        </button>
      </div>
    </div>
  );

  return (
    <div style={backgroundWrapper}>
      <Header type={user.type} isMobile={isMobile} />
      <p style={pageTitle}>Utente</p>
      {newAccountModalIsOpen && modalNewAccount}
      <div
        style={
          newAccountModalIsOpen
            ? {
                ...contentBox,
                gap: isMobile ? "10px" : "30px",
                ...displayOnModalOpen,
              }
            : { ...contentBox, gap: isMobile ? "10px" : "30px" }
        }
      >
        <div
          style={{
            width: isMobile ? "323px" : "765px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {admin && (
            <button
              style={{
                ...deleteButton,
                background: isSelectorToggled ? "#123786" : "#FF0000",
              }}
              onClick={toggle}
            >
              <div style={deleteButton}>
                <p style={deleteButtonText}>
                  {isSelectorToggled ? "Annulla" : "Elimina"}
                </p>
              </div>
            </button>
          )}
        </div>

        {renderUsers}

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p
            style={{ color: `${logMessage?.color}` }}
            className={logMessage?.isShown && "document-logMessage"}
          >
            {logMessage?.text}
          </p>
        </div>
        {admin &&
          (isSelectorToggled ? (
            <div
              style={{
                ...longButton,
                backgroundColor: "red",
                padding:
                  isSelectorToggled && isMobile ? "20px 10px" : "30px 10px",
              }}
              onClick={handleDeleteAccounts}
            >
              <p
                style={{
                  ...longbuttonText,
                  color: isSelectorToggled ? "white" : "",
                }}
              >
                Elimina Utenti selezionati
              </p>
            </div>
          ) : (
            <div
              style={{
                ...longButton,
                backgroundColor: "white",
              }}
              onClick={openNewAccountModal}
            >
              <img src="images/icons/add.png" />
              <p style={longbuttonText}>Aggiugni nuovo Utente</p>
            </div>
          ))}
      </div>
      <div
        style={{
          height: "100px",
          marginTop: "auto",
        }}
      ></div>
      <Footer />
    </div>
  );
};
export default AccountsPage;

/* const testMocResponseDocs = [
  {
    id: 12,
    firstName: "Mimmo",
    lastName: "Modem",
    birthDate: "1/1/2000",
    birthPlace: "test1",
    telephoneNumber: "1111111111",
    fiscalCode: "test1CF",
    ibanCode: "test1",
    bank: "test1",
    email: "test1@test.com",
    userRole: 0,
    contractType: "test1",
    partitaIva: "test1",
    expertise: "Web Developer",
  },
  {
    id: 13,

    firstName: "Ginno",
    lastName: "Bertolasi",
    birthDate: "2/2/2002",
    birthPlace: "test2",
    telephoneNumber: "222222222222",
    fiscalCode: "test2CF",
    ibanCode: "test2",
    bank: "test2",
    email: "test2@test.com",
    userRole: 1,
    contractType: "test2",
    partitaIva: "test2",
    expertise: "Full Stack",
  },
  {
    id: 14,
    firstName: "Ren",
    lastName: "Nato",
    birthDate: "3/3/2003",
    birthPlace: "test3",
    telephoneNumber: "33333333333",
    fiscalCode: "test3CF",
    ibanCode: "test3",
    bank: "test3",
    email: "test3@test.com",
    userRole: 2,
    contractType: "test3",
    partitaIva: "test3",
    expertise: "Designer",
  },
];
 */
