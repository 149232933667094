import { useEffect } from "react";
import { IoIosClose } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import {
  closeIcon,
  closeIconWrapper,
  link,
  linkBox,
  menuLogo,
  mobileMenuBox,
} from "../styles/mobileMenuStyle";
import Footer from "./Footer";
import LogoutArea from "./LogoutArea";

const MobileMenu = ({ blok, type, token, setIsMenuOpen }) => {
  const navigate = useNavigate();
  const location = window.location.pathname;

  const normal = type === "normal";
  const manager = type === "manager";
  const admin = type === "admin";

  const handleLink = (path) => {
    setIsMenuOpen(false);
    navigate(path);
  };

  return (
    <div style={mobileMenuBox}>
      <div style={closeIconWrapper}>
        <IoIosClose
          style={closeIcon}
          onClick={() => {
            setIsMenuOpen(false);
          }}
        />
      </div>
      <img style={menuLogo} src="/images/3sy.png" />
      <div style={linkBox}>
        <p
          style={
            location === "/profile"
              ? { ...link, color: "#FFFFFF" }
              : { ...link, color: "rgba(255, 255, 255, 0.6)" }
          }
          onClick={() => handleLink("/profile")}
        >
          PROFILO
        </p>
        <div
          className="headerLink"
          style={
            location === "/documents"
              ? { ...link, color: "#FFFFFF" }
              : { ...link, color: "rgba(255, 255, 255, 0.6)" }
          }
        >
          {normal ? (
            <p onClick={() => handleLink("/documents")}>DOCUMENTI</p>
          ) : (
            <p onClick={() => handleLink("/accounts")}>UTENTI</p>
          )}
        </div>
      </div>

      <LogoutArea token={token} />
      <Footer />
    </div>
  );
};
export default MobileMenu;
