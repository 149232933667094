import { privacyPolicyLink, termsAndConditionsLink } from "../consts/URLs";
import {
  bottomLinks,
  bottomLinksBox,
  footerBox,
  mailTo,
} from "../styles/footerStyle";

const Footer = ({ blok }) => {
  return (
    <div style={footerBox}>
      <div style={bottomLinksBox}>
        <p
          style={{ ...bottomLinks, cursor: "pointer" }}
          onClick={() => window.open(privacyPolicyLink)}
        >
          Privacy Policy
        </p>
        <p
          style={{ ...bottomLinks, cursor: "pointer" }}
          onClick={() => window.open(termsAndConditionsLink)}
        >
          Termini e condizioni
        </p>
        <div
          style={{
            ...bottomLinks,
            cursor: "pointer",
          }} /* onClick={() => window.open()} */
        >
          <p
            style={mailTo}
            onClick={() => (document.location = "mailto:info@3sy.dev")}
          >
            Contattaci
          </p>
        </div>
      </div>
    </div>
  );
};
export default Footer;
