export const profileInputsNames = [
  {
    name: "name",
    type: "text",
    placeholder: "Nome",
  },
  {
    name: "lastName",
    type: "text",
    placeholder: "Cognome",
  },
];

export const profileInputsBio = [
  {
    name: "birthDate",
    type: "text",
    placeholder: "Data di nascita",
    innerPlaceHolder: "Data di nascita gg/mm/yyyy",
  },
  {
    name: "birthPlace",
    type: "text",
    placeholder: "Luogo di nascita",
  },
  {
    name: "telephoneNumber",
    type: "text",
    placeholder: "Numero di telefono",
  },
  {
    name: "fiscalCode",
    type: "text",
    placeholder: "Codice Fiscale",
  },
];

export const profileInputsBank = [
  {
    name: "ibanCode",
    type: "text",
    placeholder: "Iban",
  },
  {
    name: "bank",
    type: "text",
    placeholder: "Banca",
  },
];

export const profileInputsContract = [
  {
    name: "contractType",
    type: "text",
    placeholder: "Tipo di Contratto",
    options: [
      {
        value: "Assunzione tempo indeterminato",
      },
      {
        value: "Assunzione tempo determinato",
      },
      {
        value: "Partita iva",
      },
      {
        value: "Prestazione occasionale",
      },
    ],
  },
  {
    name: "userRole",
    type: "text",
    placeholder: "Ruolo",
    options: [
      {
        value: "Web designer",
      },
      {
        value: "Front end developer",
      },
      {
        value: "Back end developer",
      },
      {
        value: "SMM",
      },
      {
        value: "Amministrazione",
      },
    ],
  },
];
