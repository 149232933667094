import { storyblokEditable } from "@storyblok/react";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AccountInfoPage from "../pages/AccountInfoPage";
import AccountsPage from "../pages/AccountsPage";
import DocumentsPage from "../pages/DocumentsPage";
import LoginPage from "../pages/LoginPage";
import ProfilePage from "../pages/ProfilePage";
import PwChangePage from "../pages/PwChangePage";
import PwRecoveryPage from "../pages/PwRecoveryPage";
import PwResetPage from "../pages/PwResetPage";

import "../styles/wallpaper.css";
import { getCookie } from "../utils/handleCookie";

function Page({ blok }) {
  const [token, setToken] = useState();
  const [user, setUser] = useState({});

  const normal = user.type === "normal";
  const manager = user.type === "manager";
  const admin = user.type === "admin";

  const [accountSelected, setAccountSelected] = useState({});

  useEffect(() => {
     /* console.log(document.cookie,window.location.pathname);   */
    if (getCookie("appToken") && getCookie("userEssential")) {
      setToken(getCookie("appToken"));
      const user = JSON.parse(getCookie("userEssential"));
      setUser(user);
      if (getCookie("accountSelectedId")) {
        setAccountSelected({ id: getCookie("accountSelectedId") });
      }
    } else if (
      window.location.pathname === `/login` ||
      window.location.pathname === `/reset-password/` ||
      window.location.pathname === `/recovery-password`
    ) {
    }  else {
      window.location.href = window.location.origin + "/login";
    }  
  }, []);

  return (
    <div {...storyblokEditable(blok)}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            element={
              <LoginPage
                blok={blok}
                setUser={setUser}
                token={token}
                setToken={setToken}
              />
            }
          />
          {/* {token && (normal || manager || admin) && (
            <Route
              path="/firstaccess"
              element={
                <FirstAccessPage blok={blok} user={user} setUser={setUser} />
              }
            />
          )} */}
          <Route
            path="/recovery-password"
            element={<PwRecoveryPage blok={blok} />}
          />
          <Route
            path="/reset-password"
            element={
              <PwResetPage
                blok={blok}
                setUser={setUser}
                token={token}
                setToken={setToken}
              />
            }
          />
          {token && (normal || manager || admin) && (
            <Route
              path="/change-password"
              element={<PwChangePage blok={blok} user={user} token={token} />}
            />
          )}
          {token && (normal || manager || admin) && (
            <Route
              path="/profile"
              element={
                <ProfilePage
                  blok={blok}
                  user={user}
                  setUser={setUser}
                  accountSelected={accountSelected}
                  setAccountSelected={setAccountSelected}
                  token={token}
                  setToken={setToken}
                />
              }
            />
          )}
          {token && (normal || manager || admin) && (
            <Route
              path="/documents"
              element={
                <DocumentsPage
                  blok={blok}
                  user={user}
                  token={token}
                  setAccountSelected={setAccountSelected}
                  accountSelected={accountSelected}
                />
              }
            />
          )}
          {token && (manager || admin) && (
            <Route
              path="/accounts"
              element={
                <AccountsPage
                  blok={blok}
                  user={user}
                  token={token}
                  setAccountSelected={setAccountSelected}
                />
              }
            />
          )}
          {token && (manager || admin) && (
            <Route
              path="/account-info"
              element={
                <AccountInfoPage
                  blok={blok}
                  setUser={setUser}
                  user={user}
                  token={token}
                  setAccountSelected={setAccountSelected}
                  accountSelected={accountSelected}
                />
              }
            />
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default Page;
