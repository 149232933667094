import { useState } from "react";
import { LOGIN } from "../api/endpointList";
import { apiURL } from "../consts/URLs";
import { fetchRequest } from "../utils/fetchRequest";
import { getOptions } from "../utils/getOption";

const useLogin = () => {
  const [loginResponse, setResponse] = useState();
  const [loginResult, setResult] = useState();

  const login = (email, password) => {
    const url = `${apiURL}/${LOGIN}?email=${email}&password=${password}`;
    const options = getOptions({ method: "POST" });
    fetchRequest(url, options, setResponse, setResult);
  };

  return { loginResponse, loginResult, login };
};
export default useLogin;
