import { useEffect } from "react";
import { getCookie } from "../utils/handleCookie";
import ProfilePage from "./ProfilePage";

const AccountInfoPage = ({
  blok,
  setAccountSelected,
  setUser,
  user,
  accountSelected,
  token,
}) => {
  const viewOnly = true;

  useEffect(() => {
    if (getCookie("accountSelectedId")) {
      setAccountSelected({ id: getCookie("accountSelectedId") });
    }
  }, []);

  return ProfilePage({
    blok: blok,
    user: user,
    accountSelected,
    setAccountSelected,
    setUser,
    viewOnly,
    token,
  });
};
export default AccountInfoPage;
