import { isMobile } from "../consts/conditionals";
import { backgroundWrapper } from "./contentGeneralStyle";

 export const footerBox = { 
  /* width:"100%",  */
};
export const bottomLinksBox = {
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  justifyContent:"center",
  flexDirection: "row",
  alignItems: "flex-start",
  paddingBottom: "40px",
  gap: "30px", /* 
  width: "400px", */
};
export const bottomLinks = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize:isMobile? "11px":"13px",
  lineHeight: "12px",
  textAlign: "center",

  color: "#FFFFFF",
  opacity: "0.6",
};
export const mailTo={
  color:"#FFFFFF"
}