import { useState } from "react";
import {
  ACCOUNTS,
  CHANGE_PASSWORD,
  SUBMISSIONS,
  UPLOAD_DOC,
} from "../api/endpointList";
import { apiURL } from "../consts/URLs";
import { fetchRequest } from "../utils/fetchRequest";
import { getOptions } from "../utils/getOption";
import { urlRequestParamBuilder } from "../utils/urlRequestParamBuilder";

const useDocument = (token) => {
  const [uploadDocumentResponse, setUploadDocumentResponse] = useState();
  const [uploadDocumentResult, setUploadDocumentResult] = useState();

  const [createDocumentResponse, setCreateDocumentResponse] = useState();
  const [createDocumentResult, setCreateDocumentResult] = useState();

  const [getDocumentResponse, setGetDocumentResponse] = useState();
  const [getDocumentResult, setGetDocumentResult] = useState();

  const [modifyDocumentResponse, setModifyDocumentResponse] = useState();
  const [modifyDocumentResult, setModifyDocumentResult] = useState();

  const [deleteDocumentResponse, setDeleteDocumentResponse] = useState();
  const [deleteDocumentResult, setDeleteDocumentResult] = useState();

  const createDocument = (accountId, newDocument) => {
    const requestParams = urlRequestParamBuilder(newDocument);
    const url = `${apiURL}/${ACCOUNTS}/${accountId}/${SUBMISSIONS}${requestParams}`;
    const options = getOptions({ method: "POST", token: token });
    fetchRequest(
      url,
      options,
      setCreateDocumentResponse,
      setCreateDocumentResult
    );
  };

  const getDocument = (id) => {
    const url = `${apiURL}/${ACCOUNTS}/${SUBMISSIONS}/${id}`;
    const options = getOptions({ method: "GET", token: token });
    fetchRequest(url, options, setGetDocumentResponse, setGetDocumentResult);
  };

  const modifyDocument = (accountId, documentId, documentModified) => {
    const requestParams = urlRequestParamBuilder(documentModified);
    const url = `${apiURL}/${ACCOUNTS}/${accountId}/${SUBMISSIONS}/${documentId}${requestParams}`;
    const options = getOptions({ method: "PUT", token: token });
    fetchRequest(
      url,
      options,
      setModifyDocumentResponse,
      setModifyDocumentResult
    );
  };

  const deleteDocument = (accountId, documentId) => {
    const url = `${apiURL}/${ACCOUNTS}/${accountId}/${SUBMISSIONS}/${documentId}`;
    const options = getOptions({ method: "DELETE", token: token });
    fetchRequest(
      url,
      options,
      setDeleteDocumentResponse,
      setDeleteDocumentResult
    );
  };

  const uploadDocument = (accountId, documentId,status, fileBase64) => {
    const url = `${apiURL}/${ACCOUNTS}/${accountId}/${SUBMISSIONS}/${documentId}/${UPLOAD_DOC}?status=${status}`;
    const fData = new FormData();
    fData.append("document", "data:application/pdf;base64," + fileBase64);
    fetchRequest(
      url,
      getOptions({ method: "POST", formData: fData, token: token }),
      setUploadDocumentResponse,
      setUploadDocumentResult
    );
  };

  const RESPONSES = {
    uploadDocumentResponse,
    createDocumentResponse,
    getDocumentResponse,
    modifyDocumentResponse,
    deleteDocumentResponse,
  };

  const RESULTS = {
    uploadDocumentResult,
    createDocumentResult,
    getDocumentResult,
    modifyDocumentResult,
    deleteDocumentResult,
  };

  const CTAs = {
    uploadDocument,
    createDocument,
    getDocument,
    modifyDocument,
    deleteDocument,
  };

  return { RESPONSES, RESULTS, CTAs };
};
export default useDocument;
