import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import MobileMenu from "./MobileMenu";
import {
  easyLogoHeader,
  headerBox,
  headerLinkBox,
  headerLinkText,
  linksBox,
} from "../styles/headerStyle";
import { isMobile } from "../consts/conditionals";

const Header = ({ blok, type }) => {
  const location = window.location.pathname;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div style={headerBox}>
      {isMenuOpen && <MobileMenu type={type} setIsMenuOpen={setIsMenuOpen} />}
      <img style={easyLogoHeader} src="/images/3sy.png" />
      {isMobile ? (
        <GiHamburgerMenu
          style={{ color: "white", fontSize: "xx-large", cursor: "pointer" }}
          onClick={() => {
            setIsMenuOpen((prevState) => !prevState);
          }}
        />
      ) : (
        <div style={linksBox}>
          :
          <p style={headerLinkBox}>
            <Link
              style={{
                ...headerLinkText,
                color:
                  location === "/profile"
                    ? "#FFFFFF"
                    : "rgba(255, 255, 255, 0.6)",
              }}
              to="/profile"
            >
              PROFILO
            </Link>
          </p>
          <p
            style={{
              ...headerLinkText,
              color:
                location === "/documents"
                  ? "#FFFFFF"
                  : "rgba(255, 255, 255, 0.6)",
            }}
          >
            {type === "normal" ? (
              <Link style={headerLinkText} to="/documents">
                DOCUMENTI
              </Link>
            ) : (
              <Link style={headerLinkText} to="/accounts">
                UTENTI
              </Link>
            )}
          </p>
        </div>
      )}
    </div>
  );
};
export default Header;
