import { useState } from "react";
import { CHANGE_PASSWORD } from "../api/endpointList";
import { apiURL } from "../consts/URLs";
import { fetchRequest } from "../utils/fetchRequest";
import { getOptions } from "../utils/getOption";

const useChangePassword = (token) => {
  const [changePasswordResponse, setChangePasswordResponse] = useState();
  const [changePasswordResult, setChangePasswordResult] = useState();

  const changePassword = (
    email,
    oldPassword,
    newPassword,
    confirmNewPassword
  ) => {
    const url = `${apiURL}/${CHANGE_PASSWORD}?email=${email}&current_password=${oldPassword}&password=${newPassword}&password_confirmation=${confirmNewPassword}`;
    const options = getOptions({ method: "POST", token: token });
    fetchRequest(
      url,
      options,
      setChangePasswordResponse,
      setChangePasswordResult
    );
  };

  return { changePasswordResponse, changePasswordResult, changePassword };
};
export default useChangePassword;
