export const changePasswordInputs = [
  {
    name: "confirmOldPassword",
    type: "password",
    placeholder: "Vecchia Password",
  },
  {
    name: "newPassword",
    type: "password",
    placeholder: "Nuova Password",
  },
  {
    name: "confirmNewPassword",
    type: "password",
    placeholder: "Ripeti Nuova Password",
  },
];

export const resetPasswordInputs = [
  {
    name: "password",
    type: "password",
    placeholder: "Nuova Password",
  },
  {
    name: "confirmPassword",
    type: "password",
    placeholder: "Ripeti Nuova Password",
  },
];
