export const documentStatuses = {
  valid: "valid",
  document_required: "document required",
  signature_required: "signature required",
  in_approval: "in approval",
  rejected: "rejected",
};
export const dcoumentTypes = [
  "INBX - Documento Generico",
  "FX - Fattura",
  "CNT - Contratto prestazione occasionale",
  "RCP - Ricevuta comunicazione preventiva",
  "IDEN - Documento di identità",
  "CF - Codice Fiscale",
  "RD - Ritenuta d'acconto",
  "BP - Busta paga",
  "CT - Contratto",
  "AC - Allegato Contratto",
];
