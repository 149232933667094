import { useState } from "react";
import { FORGOT_PASSWORD, LOGOUT, RESET_PASSWORD } from "../api/endpointList";
import { apiURL } from "../consts/URLs";
import { fetchRequest } from "../utils/fetchRequest";
import { getOptions } from "../utils/getOption";
import { urlRequestParamBuilder } from "../utils/urlRequestParamBuilder";

const useResetPassword = () => {
  const [resetPasswordResponse, setResetPasswordResponse] = useState();
  const [resetPasswordResult, setResetPasswordResult] = useState();

  const resetPassword = ( 
    email,
    tempToken, 
    password,
    confirmPassword
  ) => {
    const data = { 
      email: email,
      token: tempToken, 
      password: password,
      password_confirmation: confirmPassword,
    };
    const parameters = urlRequestParamBuilder(data); 
    const url = `${apiURL}/${RESET_PASSWORD}${parameters}`;
    const options = getOptions({ method: "POST", token: tempToken });
    fetchRequest(
      url,
      options,
      setResetPasswordResponse,
      setResetPasswordResult
    );
  };

  return { resetPasswordResponse, resetPasswordResult, resetPassword };
};
export default useResetPassword;
