import { isMobile } from "../consts/conditionals";
import { background, secondaryA, text } from "./palette";

export const backgroundWrapper = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "100vw 100vh",
  height: "100vh",
  backgroundColor: background.hex,
};

export const accessPageTitleStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  textAlign: "center",
  color: "#ffffff",
  width: "fit-content",
  height: "22px",
  fontSize: "20px",
  lineHeight: "22px",
};

export const accessMainBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
  background: "rgba(62, 62, 62, 0.5)",
  backdropFilter: "blur(20px)",
  paddingTop: isMobile ? "40px" : "30px",
  paddingBottom: isMobile ? "10px" : "15px",
  width: isMobile ? "266px" : "342px",
  height: isMobile ? "324px" : "478px",
};

export const accessMainLogo = {
  width: isMobile ? "130px" : "187px",
  height: isMobile ? "66px" : "97.86px",
};

export const accessInput = {
  outline: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: isMobile ? "15px" : "17px",
  lineHeight: "17px",
  border: "none",
  background: "#ffffff", 
  padding: "0 13px",
  width: isMobile ? "199px" : "263.29px",
  height: isMobile ? "39px" : "57.54px",
};


export const eyeIcon = {
  position: "absolute",
  height: isMobile ? "37.5px" : "59.4px",
  fontSize: isMobile ? "large" : "x-large",
  transform: isMobile ? "translateX(-30px)" : "translateX(-40px)",
  color: "#9f9f9f",
};

export const conditionBox = {
  display: "flex",
  flexDirection: "column",
  gap: "20px",
};

export const checkboxContainer = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  textAlign: "left",
  gap: isMobile ? "15px" : "15px",
  width: isMobile ? "225px" : "289.29px",
  height: isMobile ? "13px" : "13px",
};

export const checkboxInput = {
  width: "13px",
  height: "13px",
  background: "#ffffff",
};

export const accessLink = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "15px",
  lineHeight: "14px",
  textAlign: "center",
  color: "#ffffff",
  /* 
  width: "300px", */
  height: "13px",
};

export const accessButton = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: secondaryA.hex,
  cursor: "pointer",
  border: "none",
  margin: "0 auto",

  width: isMobile ? "225px" : "289.29px",
  height: isMobile ? "39px" : "57.54px",
};

export const accessButtonText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "17px",
  color: text.hex,

  fontSize: isMobile ? "" : "15px",
  /* width: isMobile ? "135px" : "173.57px"  
  height: isMobile ? "13px" : "19.18px", */
};

export const forgotPwLink = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  /* width: "178px", */
  height: "20px",
};

export const logBox = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px",
  gap: "10px",/* 
  background: "#ff0000", */

  width: "266px",
  height: isMobile ? "54px" : "60px",
};

export const logText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "15px",
  lineHeight: "133.02%",
  textAlign: "center",
  color: "red",
/* 
  width: "261px",  *//* 
  height: isMobile ? "34px" : "40px", */
};

export const recoveryPassowrdInstructions = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "15px",
  lineHeight: "133.02%",
  textAlign: "center",
  color: "#ededed",

  /* width: "221px", */
  height: "60px",
};
