import { useState } from "react";
import { ACCOUNTS, UPLOAD_IMAGE } from "../api/endpointList";
import { apiURL } from "../consts/URLs";
import { fetchRequest } from "../utils/fetchRequest";
import { getOptions } from "../utils/getOption";
import { urlRequestParamBuilder } from "../utils/urlRequestParamBuilder";

const useAccount = (token) => {
  const [uploadImageResponse, setUploadImageResponse] = useState();
  const [uploadImageResult, setUploadImageResult] = useState();

  const [getAccountResponse, setGetAccountResponse] = useState();
  const [getAccountResult, setGetAccountResult] = useState();
  
  const [modifyAccountResponse, setModifyAccountResponse] = useState();
  const [modifyAccountResult, setModifyAccountResult] = useState();
  
  const [deleteAccountResponse, setDeleteAccountResponse] = useState();
  const [deleteAccountResult, setDeleteAccountResult] = useState();

  const getAccount = (id) => {
    const url = `${apiURL}/${ACCOUNTS}/${id}`;
    const options = getOptions({ method: "GET", token: token });
    fetchRequest(url, options, setGetAccountResponse, setGetAccountResult);
  };

  const modifyAccount = (accountId,accountModified) => {  
    const requestParams = urlRequestParamBuilder(accountModified);
    const url = `${apiURL}/${ACCOUNTS}/${accountId}${requestParams}`;
    const options = getOptions({ method: "PUT", token: token });
    fetchRequest(
      url,
      options,
      setModifyAccountResponse,
      setModifyAccountResult
    );
  };

  const deleteAccount = (id) => {
    const url = `${apiURL}/${ACCOUNTS}/${id}`;
    const options = getOptions({ method: "DELETE", token: token });
    fetchRequest(url, options, setDeleteAccountResponse, setDeleteAccountResult);
  };

  const uploadImage = (userId, fileBase64) => {
    const url = `${apiURL}/${ACCOUNTS}/${userId}/${UPLOAD_IMAGE}`;
    const fData = new FormData();
    fData.append("profileImage", "data:image/png;base64," + fileBase64);
    fetchRequest(
      url,
      getOptions({ method: "POST", formData: fData, token: token }),
      setUploadImageResponse,
      setUploadImageResult
    );
  };


  const RESPONSES = {
    uploadImageResponse,
    getAccountResponse,
    modifyAccountResponse,
    deleteAccountResponse,
  };

  const RESULTS = {
    uploadImageResult,
    getAccountResult,
    modifyAccountResult,
    deleteAccountResult,
  };

  const CTAs = {
    uploadImage,
    getAccount,
    modifyAccount,
    deleteAccount
  };

  return [RESPONSES, RESULTS, CTAs];
};
export default useAccount;
