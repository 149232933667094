import { getPropertiesNames } from "./getPropertiesNames";
import { getPropertiesValues } from "./getPropertiesValues";

export const urlRequestParamBuilder = (object) => {
  const keys = getPropertiesNames(object);
  const values = getPropertiesValues(object);
  let composedURL = "?";  
  for (let index = 0; index < keys.length; index++) {
    composedURL += `${keys[index]}=${values[index]}`;
    if (index === keys.length - 1) {
    } else composedURL += "&";
  }
  return composedURL;
};
